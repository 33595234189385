import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardBody,
  Form,
  Collapse,
  Card,
  CardHeader,
} from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ProductService from "../../../../Services/products/ProductService";
import { H6 } from "../../../../AbstractElements";
import { H5, Btn } from "../../../../AbstractElements";
import { Edit, Trash, XCircle, PlusCircle } from "react-feather";
import TailChase from "../../../Loaders/TailChase";
import Swal from "sweetalert2";
function ContributorForm({
  contributor,
  isOpen,
  toggle,
  updateContributorMutation,
  deleteContributorMutation,
  royalties,
}) {
  const [availableRoyalties, setAvailableRoyalties] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      contributor_name: contributor.contributor_name,
      contributor_role: contributor.contributor_role,
      royalty_ids: contributor.royalties
        ? contributor.royalties.map((royalty) => ({
            id: royalty.id,
            name: royalty.name,
            percentage: royalty.percentage,
          }))
        : [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "royalty_ids",
  });

  const deleteRoyalty = (index) => {
    const deletedRoyalty = fields[index];
    remove(index);

    // Find the corresponding royalty from the royalties response
    const correspondingRoyalty = royalties.find(
      (r) => r.name === deletedRoyalty.name
    );

    // Add the corresponding royalty back to the available royalties
    if (correspondingRoyalty) {
      setAvailableRoyalties((prevAvailableRoyalties) => [
        ...prevAvailableRoyalties,
        correspondingRoyalty,
      ]);
    }
  };
  const addRoyalty = (royalty) => {
    append(royalty);
    // Remove the added royalty from available royalties
    setAvailableRoyalties(
      availableRoyalties.filter((r) => r.id !== royalty.id)
    );
  };

  const addRoyaltyForm = () => {
    addRoyalty({ id: null, percentage: "", isNew: true });
  };

  //? destructuring the data object to merge the royalties array
  const handleUpdateContributor = (dataInitial) => {
    const { royalties, ...rest } = dataInitial;
    const data = {
      ...rest,
      royalty_ids: dataInitial.royalty_ids
        .filter((royalty) => {
          // Check if the royalty has an id
          return royalty.id !== undefined && royalty.id !== null;
        })
        .map((royalty) => ({
          id: parseInt(royalty.id),
          percentage: royalty.percentage,
        })),
    };
    setIsUpdating(true);

    return new Promise((resolve, reject) => {
      updateContributorMutation.mutate(
        { id: contributor.id, formData: data },
        {
          onSuccess: (data) => {
            // Check if data.royalty_ids is defined and not empty
            if (data.royalty_ids && data.royalty_ids.length > 0) {
              // Remove all the fields
              for (let i = 0; i < fields.length; i++) {
                remove(i);
              }

              // Append the updated fields
              data.royalty_ids.forEach((updatedRoyalty, index) => {
                const selectedRoyalty = availableRoyalties.find(
                  (r) => r.id === updatedRoyalty.id
                );
                if (selectedRoyalty) {
                  append({
                    id: selectedRoyalty.id,
                    name: selectedRoyalty.name,
                    percentage: updatedRoyalty.percentage,
                    isNew: false,
                  });
                  // Update the form values
                  setValue(`royalty_ids[${index}].id`, selectedRoyalty.id);
                  setValue(`royalty_ids[${index}].name`, selectedRoyalty.name);
                  setValue(
                    `royalty_ids[${index}].percentage`,
                    updatedRoyalty.percentage
                  );
                }
              });
            }
            setIsUpdating(false);
            resolve();

          },
          onError: (error) => {
            reject(error);
            setIsUpdating(false);
          },
        }
      );
    });
  };

  const handleDeleteContributor = (contributorId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this contributor?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContributorMutation.mutate(contributorId, {
          onSuccess: (response) => {
            if (response.success) {
              Swal.fire(
                "Deleted!",
                "Contributor deleted successfully.",
                "success"
              );
            } else {
              Swal.fire(
                "Error!",
                "An error occurred while deleting the contributor.",
                "error"
              );
            }
          },
        });
      }
    });
  };

  useEffect(() => {
    setAvailableRoyalties(
      royalties.filter(
        (r) => !contributor.royalties.some((royalty) => royalty.id === r.id)
      )
    );
  }, [royalties, contributor.royalties]);

  return (
    <Card>
      <CardHeader className="bg-primary">
        <H5 attrH5={{ className: "mb-0" }}>
          <Btn
            attrBtn={{
              as: Card.Header,
              className: "btn btn-link text-white",
              color: "default",
              onClick: () => toggle(contributor.id),
            }}
          >
            <i className="icofont icofont-support"></i>
            {contributor.contributor_name}
          </Btn>
        </H5>
        {isOpen === contributor.id && (
          <div className="position-absolute" style={{ right: 0, top: 5 }}>
            {/* <Btn
              id="edit"
              attrBtn={{
                color: "default",
              }}
            >
              <Edit size={20} />
            </Btn> */}
            <Btn
              attrBtn={{
                color: "default",
              }}
            >
              <Trash
                size={20}
                onClick={() => handleDeleteContributor(contributor.id)}
              />
            </Btn>
          </div>
        )}
      </CardHeader>
      <Collapse isOpen={isOpen === contributor.id}>
        <CardBody>
          <Form
              className="theme-form"
              onSubmit={handleSubmit(handleUpdateContributor)}
          >

            <div className="d-flex">
              <FormGroup className="mb-3 flex-grow-1 me-1">
                <H6 attrH6={{className: "col-form-label"}}>
                  Contributor Name
                </H6>
                <Controller
                    name="contributor_name"
                    control={control}
                    defaultValue={contributor.contributor_name}
                    render={({field}) => (
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter contributor name"
                            {...field}
                        />
                    )}
                />
              </FormGroup>
              <FormGroup className="mb-3 flex-grow-1">
                <H6 attrH6={{className: "col-form-label"}}>
                  Contributor Role
                </H6>
                <Controller
                    name="contributor_role"
                    control={control}
                    defaultValue={contributor.contributor_role}
                    render={({field}) => (
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter contributor role"
                            {...field}
                        />
                    )}
                />
              </FormGroup>
            </div>
            {/* <hr />
            <Row>
              <FormGroup className="mb-3 col-3">
                <H6 attrH6={{ className: "col-form-label" }}>
                  Bank Account Number
                </H6>
                <Controller
                  name="bank_account_number"
                  control={control}
                  defaultValue={contributor.bank_account_number}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter bank account number"
                      {...field}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup className="mb-3 col-3">
                <H6 attrH6={{ className: "col-form-label" }}>
                  Bank IBAN Number
                </H6>
                <Controller
                  name="bank_iban_number"
                  control={control}
                  defaultValue={contributor.bank_iban_number}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter bank IBAN number"
                      {...field}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup className="mb-3 col-3">
                <H6 attrH6={{ className: "col-form-label" }}>
                  Bank Swift Number
                </H6>
                <Controller
                  name="bank_swift_number"
                  control={control}
                  defaultValue={contributor.bank_swift_number}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter bank Swift number"
                      {...field}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup className="mb-3 col-3">
                <H6 attrH6={{ className: "col-form-label" }}>Bank Name</H6>
                <Controller
                  name="bank_name"
                  control={control}
                  defaultValue={contributor.bank_name}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter bank name"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Row> */}
            <hr/>

            <div
                className="col-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
            >
              <H5 attrH6={{className: "col-form-label"}}>Royalties</H5>
              <Btn
                  attrBtn={{
                    color: "success",
                    className: "p-r-30",
                    type: "button",
                    style: {
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      padding: "0",
                    },
                    onClick: addRoyaltyForm,
                  }}
              >
                <PlusCircle/>
              </Btn>
            </div>
            {/* <Row>
                <FormGroup className="mb-3 col-5">
                  <H6 attrH6={{ className: "col-form-label" }}>CA IPI</H6>
                  <Controller
                    name="ca_ipi"
                    control={control}
                    defaultValue={contributor.ca_ipi}
                    render={({ field }) => (
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Enter CA IPI"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>

                <FormGroup className="mb-3 col-5">
                  <H6 attrH6={{ className: "col-form-label" }}>
                    Red Contributor ID
                  </H6>
                  <Controller
                    name="red_contributor_id"
                    control={control}
                    defaultValue={contributor.red_contributor_id}
                    render={({ field }) => (
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Enter Red Contributor ID"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Row> */}

            {fields.map((royalty, i) => (
              <FormGroup key={royalty.id} className="mb-3 col-10">
                {royalty.isNew ? (
                  <>
                    <H6 attrH6={{ className: "col-form-label" }}>
                      New Royalty
                    </H6>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <Controller
                        name={`royalty_ids[${i}].id`}
                        control={control}
                        defaultValue={royalty.id}
                        render={({ field }) => (
                          <>
                            <input type="hidden" {...field} />
                            <Input
                              className="form-control form-control-primary btn-square"
                              type="select"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                const selectedRoyalty = availableRoyalties.find(
                                  (r) => r.id === e.target.value
                                );
                                if (selectedRoyalty) {
                                  setSelectedRoyalties([
                                    ...selectedRoyalties,
                                    selectedRoyalty,
                                  ]);
                                  setAvailableRoyalties(
                                    availableRoyalties.filter(
                                      (r) => r.id !== selectedRoyalty.id
                                    )
                                  );
                                }
                              }}
                            >
                              <option value="">Select a royalty</option>
                              {availableRoyalties.map((r) => (
                                <option key={r.id} value={r.id}>
                                  {r.name}
                                </option>
                              ))}
                            </Input>
                          </>
                        )}
                      /> */}

                      <Controller
                        name={`royalty_ids[${i}].id`}
                        control={control}
                        defaultValue={royalty.id}
                        render={({ field }) => (
                          <>
                            <input type="hidden" {...field} />
                            <Input
                              className="form-control form-control-primary btn-square"
                              type="select"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                const selectedRoyalty = availableRoyalties.find(
                                  (r) => r.id === parseInt(e.target.value)
                                );
                                if (selectedRoyalty) {
                                  // Update the id of the new royalty
                                  setValue(
                                    `royalty_ids[${i}].id`,
                                    selectedRoyalty.id
                                  );
                                  setValue(
                                    `royalty_ids[${i}].name`,
                                    selectedRoyalty.name
                                  );
                                  // Update the isNew flag of the new royalty
                                  setValue(`royalty_ids[${i}].isNew`, false);
                                  // Update the royalty object
                                  royalty.id = selectedRoyalty.id;
                                  royalty.name = selectedRoyalty.name;
                                  royalty.isNew = false;
                                  // trigger();
                                }
                              }}
                            >
                              <option value="">Select a royalty</option>
                              {availableRoyalties.map((r) => (
                                <option key={r.id} value={r.id}>
                                  {r.name}
                                </option>
                              ))}
                            </Input>
                          </>
                        )}
                      />
                      <Controller
                        name={`royalty_ids[${i}].percentage`}
                        control={control}
                        defaultValue={royalty.percentage}
                        render={({ field }) => (
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter percentage"
                            style={{ marginRight: "15px" }}
                            {...field}
                          />
                        )}
                      />
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "p-r-30 ",
                          type: "button",
                          style: {
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          },
                          onClick: (e) => {
                            deleteRoyalty(i);
                          },
                        }}
                      >
                        <XCircle />
                      </Btn>
                    </div>
                  </>
                ) : (
                  <>
                    <H6 attrH6={{ className: "col-form-label" }}>
                      {royalty.name}
                    </H6>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Controller
                        name={`royalty_ids[${i}].percentage`}
                        control={control}
                        defaultValue={royalty.percentage}
                        render={({ field }) => (
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter percentage"
                            style={{ marginRight: "15px" }}
                            {...field}
                          />
                        )}
                      />
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "p-r-30 ",
                          type: "button",
                          style: {
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          },
                          onClick: (e) => {
                            deleteRoyalty(i);
                          },
                        }}
                      >
                        <XCircle />
                      </Btn>
                    </div>
                  </>
                )}
              </FormGroup>
            ))}

            <div style={{textAlign: "right"}}>
              <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    className: "mt-3",
                  }}
              >
                {isUpdating ? <TailChase/> : "Update"}
                {/* <Edit size={15} textLength={20} textAnchor="UPDATE" /> */}
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  );
}

{
  /**/
}

export default ContributorForm;
