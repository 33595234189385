import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import { Stepper, Step } from "react-form-stepper";
import IncomeStepOne from './IncomeStepOne';
import IncomeStepTwo from './IncomeStepTwo';
import { Container, Button } from 'reactstrap';

function NewIncome() {
    const [activeStep, setActiveStep] = useState(0);
    const [incomeId, setIncomeId] = useState(null);
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Breadcrumbs
                parent="Income"
                title="Incomes"
                mainTitle="Create Income"
                buttons={[
                    <Button
                        size="sm"
                        color="primary"
                        onClick={() => navigate(`${process.env.PUBLIC_URL}/app/income/income-list`)}
                    >
                        <i className="fa fa-arrow-left me-1"></i> Back to Incomes
                    </Button>,
                ]}
            />
            <Container fluid={true}>
                <Stepper activeStep={activeStep}>
                    <Step label="Income Creation" />
                    <Step label="Income File Upload" />
                </Stepper>

                {activeStep === 0 && (
                    <IncomeStepOne handleNext={handleNext} setIncomeId={setIncomeId} />
                )}
                {activeStep === 1 && (
                    <IncomeStepTwo handleBack={handleBack} handleNext={handleNext} incomeId={incomeId} />
                )}
            </Container>
        </>
    );
}

export default NewIncome;