// src/Components/Application/Incomes/index.jsx
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Input, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import IncomeService from "../../../Services/income/IncomeService";
import SpinnerLoader from "../../Loaders/Spinner";
import { toast } from "react-toastify";
import UpdateIncomeModal from "./UpdateIncomeModal";
import Swal from "sweetalert2";
import {Download, XCircle, CheckCircle} from 'react-feather';
import moment from "moment";

function IncomesContainer() {
    const queryClient = useQueryClient();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedIncome, setSelectedIncome] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const navigate = useNavigate();



    const { isLoading, isError, data, error } = useQuery({
        queryKey: ["incomes"],
        queryFn: IncomeService.getAllIncomes,
    });

    const addIncomeMutation = useMutation({
        mutationFn: IncomeService.addIncome,
        onSuccess: () => {
            toast.success("Income added successfully!");
            queryClient.invalidateQueries(["incomes"]);
            setIsAddModalOpen(false);
        },
        onError: (error) => {
            toast.error(`Failed to add income: ${error.message}`);
        },
    });

    const updateIncomeMutation = useMutation({
        mutationFn: ({ id, incomeData }) => IncomeService.updateIncome(id, incomeData),
        onSuccess: () => {
            toast.success("Income updated successfully!");
            queryClient.invalidateQueries(["incomes"]);
            setIsUpdateModalOpen(false);
        },
        onError: (error) => {
            toast.error(`Failed to update income: ${error.message}`);
        },
    });

    const deleteIncomeMutation = useMutation({
        mutationFn: IncomeService.deleteIncome,
        onSuccess: () => {
            toast.success("Income deleted successfully!");
            queryClient.invalidateQueries(["incomes"]);
        },
        onError: (error) => {
            toast.error(`Failed to delete income: ${error.message}`);
        },
    });

    const handleAddIncome = (incomeData) => {
        return new Promise((resolve, reject) => {
            addIncomeMutation.mutate(incomeData, {
                onSuccess: resolve,
                onError: reject,
            });
        });
    };

    const handleUpdateIncome = (income) => {
        setSelectedIncome(income);
        setIsUpdateModalOpen(true);
    };

    const handleSaveUpdatedIncome = (incomeData) => {
        return new Promise((resolve, reject) => {
            updateIncomeMutation.mutate({ id: selectedIncome.id, incomeData }, {
                onSuccess: resolve,
                onError: reject,
            });
        });
    };

    const handleDownloadFile = async (incomeId) => {
        try {
            const { data: fileBlob, fileName, mimeType } = await IncomeService.downloadFile(incomeId);
            const url = window.URL.createObjectURL(new Blob([fileBlob], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;
            const date = new Date();
            const timestamp = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
            link.setAttribute('download', timestamp);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            toast.error(`Failed to download file: ${error.message}`);
        }
    };


    const handleDeleteIncome = (incomeId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this income?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteIncomeMutation.mutate(incomeId);
            }
        });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        if (name === "startDate") setStartDate(value);
        if (name === "endDate") setEndDate(value);
    };



    const filteredData = Array.isArray(data?.data) ? data.data.filter(
        (income) =>
            income.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (!startDate || new Date(income.period_start) >= new Date(startDate)) &&
            (!endDate || new Date(income.period_end) <= new Date(endDate))
    ) : [];

    const tableColumns = [
        { name: "Title", selector: (row) => row.title, sortable: true },
        { name: "Description", selector: (row) => row.description, sortable: true },
        { name: "Provider", selector: (row) => row.provider, sortable: true, width: "120px" },
        { name: "Amount", selector: (row) => row.amount, sortable: true, width: "100px" },
        { name: "Currency", selector: (row) => row.currencies, sortable: true, width: "100px" },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            width: "100px",
            cell: (row) => (
                row.status === 'done' ? <CheckCircle color="green" /> : <XCircle color="red" />
            )
        },
        { name: "Period Start", selector: (row) => moment(row.period_start).format("YYYY-MM-DD"), sortable: true, width: "130px" },
        { name: "Period End", selector: (row) => moment(row.period_end).format("YYYY-MM-DD"), sortable: true, width: "120px" },
        {
            name: "File",
            selector: (row) => row.file,
            cell: (row) => (
                <Download
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDownloadFile(row.id)}
                />
            ),
            sortable: false,
            width: "100px"
        },
        {
            name: "Raw File",
            selector: (row) => row.raw_file,
            cell: (row) => (
                row.raw_file ? (
                    <Download
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDownloadFile(row.id)}
                    />
                ) : (
                    "Not available yet"
                )
            ),
            sortable: false,
            width: "150px"
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="todo">
                    <div className="todo-list-wrapper">
                        <div className="todo-list-container todo-list-footer">
                            <ul className="simple-list">
                                <li className="task border-0">
                                    <div className="task-container">
                                        <span className="task-action-btn">
                                            <span
                                                className="action-box large icon-edit"
                                                title="Update"
                                                onClick={() => handleUpdateIncome(row)}
                                            >
                                                <i className="icon">
                                                    <i className="fa fa-edit"></i>
                                                </i>
                                            </span>
                                            <span
                                                className="action-box large delete-btn"
                                                title="Delete"
                                                onClick={() => handleDeleteIncome(row.id)}
                                            >
                                                <i className="icon">
                                                    <i className="icon-trash"></i>
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ),
            center: true,
        },
    ];

    if (isLoading) return <SpinnerLoader />;
    if (isError) return <div>Error loading incomes: {error.message}</div>;

    return (
        <>
            <Breadcrumbs
                parent="Incomes"
                title="Incomes"
                mainTitle="Incomes List"
                buttons={[
                    <Button
                        size="sm"
                        color="primary"
                        onClick={() => navigate(`${process.env.PUBLIC_URL}/app/income/income-new`)}
                    >
                        <i className="fa fa-plus me-1"></i> Add Income
                    </Button>,
                ]}
            />

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <h5>Incomes Table</h5>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <Input
                                            type="text"
                                            placeholder="Search by title"
                                            value={searchTerm}
                                            className="form-control"
                                            onChange={handleSearch}
                                            style={{ width: "200px" }}
                                        />
                                        <Input
                                            type="date"
                                            name="startDate"
                                            value={startDate}
                                            className="form-control"
                                            onChange={handleDateChange}
                                        />
                                        <Input
                                            type="date"
                                            name="endDate"
                                            value={endDate}
                                            className="form-control"
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={tableColumns}
                                    data={filteredData}
                                    striped={true}
                                    center={true}
                                    responsive={true}
                                    pagination
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <UpdateIncomeModal
                isOpen={isUpdateModalOpen}
                toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
                onSave={handleSaveUpdatedIncome}
                income={selectedIncome}
            />
        </>
    );
}

export default IncomesContainer;