// PersonalInfoTab.jsx
import React from "react";
import {
  Form,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import { Btn, H4 } from "../../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Update } from "../../../../../Constant";
const PersonalInfoTab = ({ userData, onEditSubmit }) => {
  const { control, handleSubmit } = useForm();

  return (
    <Form className="card theme-form" onSubmit={handleSubmit(onEditSubmit)}>
      <CardHeader>
        <H4 attrH4={{ className: "card-title mb-0" }}>Personal Info</H4>
        <div className="card-options">
          <a className="card-options-collapse" href="#javascript">
            <i className="fe fe-chevron-up"></i>
          </a>
          <a className="card-options-remove" href="#javascript">
            <i className="fe fe-x"></i>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="4" md="6">
            <FormGroup>
              <Label for="fullname">Full Name</Label>
              <Controller
                name="fullname"
                control={control}
                defaultValue={userData?.fullname || ""}
                render={({ field }) => (
                  <Input {...field} id="fullname" placeholder="Full Name" />
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="4" md="6">
            <FormGroup>
              <Label for="name">Name</Label>
              <Controller
                name="name"
                control={control}
                defaultValue={userData?.name || ""}
                render={({ field }) => (
                  <Input {...field} id="name" placeholder="Name" />
                )}
              />
            </FormGroup>
          </Col>
          {/* <Col sm="4" md="6">      
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={userData?.email}
                      render={({ field }) => (
                        <Input {...field} id="email" placeholder="Email" />
                      )}
                    />
                  </FormGroup>
                  </Col> */}
          <Col sm="4" md="6">
            <FormGroup>
              <Label for="username">Username</Label>
              <Controller
                name="username"
                control={control}
                defaultValue={userData?.username || ""}
                render={({ field }) => (
                  <Input {...field} id="username" placeholder="Username" />
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="4" md="6">
            <FormGroup>
              <Label for="address">Address</Label>
              <Controller
                name="address"
                control={control}
                defaultValue={userData?.address || ""}
                render={({ field }) => (
                  <Input {...field} id="address" placeholder="Address" />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <Btn attrBtn={{ color: "primary", type: "submit" }}>
          {Update} Personal Info
        </Btn>
      </CardFooter>
    </Form>
  );
};

export default PersonalInfoTab;
