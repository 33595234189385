import axiosInstance from "../../api/axios";

const ContributorService = {


    getAllContributors: async () => {
    try {
      const response = await axiosInstance.get("/contributors");
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch contributors", error);
      return { success: false, error };
    }
  },

//   getContributorById: async (id) => {
//     try {
//       const response = await axiosInstance.get(`/contributors/${id}`);
//       return { success: true, data: response.data };
//     } catch (error) {
//       console.error(`Failed to fetch contributor with id ${id}`, error);
//       return { success: false, error };
//     }
//   },


    updateContributor: async ({ id, formData }) => {
        try {
            const response = await axiosInstance.put(`/contributors/${id}`, formData);
            return { success: true, data: response.data };
        } catch (error) {
            console.error(`Failed to update contributor with id ${id}`, error);
            return { success: false, error };
        }
    },

};

export default ContributorService;