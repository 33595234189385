// Routers.js
import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "./LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import AuthService from "../Services/auth/AuthService";
import { useAuth } from "../_helper/Auth/AuthProvider";
import Error403 from "../Components/Pages/ErrorPages/ErrorPage403";

const Routers = () => {
  const auth = useAuth();
  const { authenticated, setAuthenticated, logout, isLoggedIn } = auth || {};
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  // console.log("accountStatus from Routers", accountSecurity);
  useEffect(() => {
    setAuthenticated(AuthService.isAuthenticated());
  }, [setAuthenticated]);

  const handleLogout = () => {
    // AuthService.logout();
    logout();
  };

  // console.log("accountSecurity", accountSecurity);
  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {authenticated ? (
              <>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}`}
                  element={
                    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
                  }
                />
                <Route
                  exact
                  path={`/`}
                  element={
                    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
                  }
                />
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          {authenticated ? (
            <Route
              exact
              path={`${process.env.PUBLIC_URL}`}
              element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}
            />
          ) : (
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Signin />}
            />
          )}

          {authRoutes.map(({ path, Component }, i) => (
            <Route
              path={path}
              element={
                path === `${process.env.PUBLIC_URL}/login/otp-verif/:param` &&
                !isLoggedIn ? (
                  <Navigate to={`${process.env.PUBLIC_URL}/login`} />
                ) : authenticated ? (
                  <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
                ) : (
                  <Component />
                )
              }
              key={i}
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
