import FilterProvider from "./_helper/Filter/FilterProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import UserProvider from "./_helper/User/UserProvider";
import LabelProvider from "./_helper/Label/LabelProvider";
import { AuthProvider } from "./_helper/Auth/AuthProvider";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()
const Providers = ({ children }) => (

  
  <AuthProvider>
     <QueryClientProvider client={queryClient}>
    <CustomizerProvider>
      <FilterProvider>
        <UserProvider>
          <LabelProvider>
            <AnimationThemeProvider>{children}</AnimationThemeProvider>
          </LabelProvider>
        </UserProvider>
      </FilterProvider>
    </CustomizerProvider>
    </QueryClientProvider>
  </AuthProvider>
);

export default Providers;
