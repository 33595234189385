import React from 'react'
import { Breadcrumbs, H5 , H6, Btn } from '../../../../AbstractElements'
import { Container, Row, Col, Card, CardBody, CardHeader, Input } from 'reactstrap'
import DataTable from 'react-data-table-component';
import ExpenseService from '../../../../Services/expenses/ExpenseService';
import { useQuery } from '@tanstack/react-query';


function MainExpenses() {


  const { isPending , isError, data, error } = useQuery({
    queryKey: ["inhouseExpenses"],
    queryFn: ExpenseService.getInhouseExpenses,
  });

  const { isPending:isCategoriesPending, isError:isCategoriesError, data:categories, error:categoryError } = useQuery({
    queryKey: ["inhouseCategories"],
    queryFn: ExpenseService.getInhouseCategories,
  });



  if (isCategoriesPending || isPending) {
    return 'Loading...';
  }
  
  if (isCategoriesError || isError) {
    return <p>An error has occurred: {categoryError.message}</p>;
  }

  const tableColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Artist ID",
      selector: (row) => row.artistId,
      sortable: true,
    },
    {
      name: "Contributor ID",
      selector: (row) => row.contributorId,
      sortable: true,
    },
   
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Currencies",
      selector: (row) => row.currencies,
      sortable: true,
    },
    {
      name: "File",
      selector: (row) => row.file,
      sortable: true,
    },
    {
      name: "States",
      selector: (row) => row.states,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container todo-list-footer">
                <ul className="simple-list">
                  <li className="task border-0">
                    <div className="task-container">
                    <span className="task-action-btn">
                      <span
                          className="action-box large icon-edit"
                          title="Update"
                          // onClick={() => handleUpdateUser(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                          className="action-box large delete-btn"
                          title="Delete"
                          // onClick={() => handleDeleteUser(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      ),
      center: true,
    },
  ];

  const dummyData = [
    {
      artistId: "1",
      contributorId: "1",
      title: "Title 1",
      category: "Category 1",
      amount: "100",
      currencies: "USD",
      file: "File 1",
      states: "State 1",
    },
    // Add more data as needed
  ];



  return (
    <>
     <Breadcrumbs
        parent="Expenses"
        title="Expenses"
        mainTitle="In-House Expenses"
        buttons={[
          <Input type="select" name="select" id="exampleSelect" className='form-control'>
          <option defaultChecked>Filter By Category</option>
          {categories.data.map((category, index) => (
            <option key={index} value={category.id}>{category.title}</option>
          ))}
        </Input>,
          <Btn
          attrBtn={{
            size: "sm",
            color: "primary",
          }}
        >
          Add Expense
        </Btn>,
      
        ]}
      />

<Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5>In-House Expenses Table</h5>
                <Input
                  type="text"
                  placeholder="Search by title, category, or amount"
                  className="form-control"
                  style={{ width: "400px" }}
                />
              </div>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={tableColumns}
                data={dummyData}
                striped={true}
                center={true}
                responsive={true}
                pagination
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    
    </>
  )
}

export default MainExpenses