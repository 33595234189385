import axiosInstance from "../../api/axios";

const UserService = {
  // register: async (userData, imageFile) => {
  //   try {
  //     axiosInstance.defaults.headers.common["Content-Type"] =
  //       "multipart/form-data";
  //     const formData = new FormData();
  //     for (const key in userData) {
  //       formData.append(key, userData[key]);
  //     }
  //     formData.append("image_label", imageFile);
  //     const response = await axiosInstance.post("/register", formData);
  //     return { success: true, data: response.data };
  //   } catch (error) {
  //     console.error("Registration failed", error);
  //     return { success: false, error };
  //   }
  // },

  createUser: async (userData) => {
    try {
      const response = await axiosInstance.post("/register", userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (!response.data.success) {
        throw new Error('Failed to create user');
      }
  
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to create user", error);
      throw error;
    }
  },
  
  updateUser: async ({ id, formData }) => {
    try {
      const response = await axiosInstance.post(`/users/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (!response.data.success) {
        throw new Error('Failed to update user');
      }
  
      return response.data;
    } catch (error) {
      console.error("Failed to update user", error);
      throw error;
    }
  },
  
  deleteUser: async (id) => {
    try {
      const response = await axiosInstance.delete(`/users/${id}`);
  
      if (!response.data.success) {
        throw new Error('Failed to delete user');
      }
  
      return response.data;
    } catch (error) {
      console.error("Failed to delete user", error);
      throw error;
    }
  },



  getAllUsers: async () => {
    try {
      const response = await axiosInstance.get("/users");
      return { success: true, data: response.data.users };
    } catch (error) {
      console.error("Failed to fetch users", error);
      return { success: false, error };
    }
  },

  updatePersonalDetails: async (userDetails) => {
    try {
      const response = await axiosInstance.post(
        "/users/update_personal_details",
        userDetails
      );
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to update personal details", error);
      return { success: false, error };
    }
  },

  uploadImage: async (imageFile) => {
    try {
      //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axiosInstance.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
      const formData = new FormData();
      formData.append("image", imageFile);
      const response = await axiosInstance.post(
        "/users/update_personal_details",
        formData
      );
      const { message, user, url } = response.data;
      // console.log("response.data", response.data);
      return { success: true, message, user, url };
    } catch (error) {
      console.error("Failed to upload image", error);
      return { success: false, error };
    }
  },

  updateBankDetails: async (bankDetails) => {
    try {
      const response = await axiosInstance.post(
        "users/update_bank_details",
        bankDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error updating bank details", error);
      throw error;
    }
  },

  getBankDetails: async () => {
    try {
      const response = await axiosInstance.get("users/bank_details");
      return { success: true, data: response.data.bankDetails };
    } catch (error) {
      console.error("Error fetching bank details", error);
      return { success: false, error };
    }
  },

  updateUserPersonalDetails: async (userDetails) => {
    try {
      const response = await axiosInstance.post(
        "/users/updateUserByadmin",
        userDetails
      );
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to update personal details", error);
      return { success: false, error };
    }
  },
};

export default UserService;
