// src/Components/Application/Users/UserEditModal.jsx
import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
} from "reactstrap";

import { Btn, Image, H6, UL, LI, H5 } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import FooterCard from "../../Common/Component/FooterCard";
import TailChase from "../../Loaders/TailChase";

const NewUserModal = ({ isOpen, toggle, onSave }) => {
  // const fileInputRef = useRef();

  // const handleImageClick = () => {
  //   fileInputRef.current.click();
  // };

  const { control, handleSubmit, setValue, reset } = useForm({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImageSelected, setNewImageSelected] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const handleImageChange = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     let img = e.target.files[0];
  //     setSelectedImage(img);
  //     setNewImageSelected(true);
  //     setValue('image', img); // Manually set the value of the 'image' field
  //   } else {
  //     setSelectedImage(null);
  //     setNewImageSelected(false);
  //     setValue('image', null); // Manually set the value of the 'image' field
  //   }
  // };

  useEffect(() => {
    if (!isOpen) {
      setValue("image", "");
      setSelectedImage(null);
      setNewImageSelected(false);
    }
  }, [isOpen, setValue]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(img);
      setNewImageSelected(true);
    } else {
      setSelectedImage(null);
      setNewImageSelected(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    // console.log("boolean", newImageSelected);
    // console.log("data", data);
    Object.keys(data).forEach((key) => {
      if (key !== "image") {
        formData.append(key, data[key]);
      }
    });
    if (newImageSelected) {
      formData.append("image", selectedImage);
    }
    onSave(formData).then(() => {
      reset();
      setIsLoading(false);
      setNewImageSelected(false);
      setSelectedImage(null);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}> New User Form</ModalHeader>
      <ModalBody>
        <Card>
          {/* <HeaderCard title="Fill Out User Form" /> */}
          <CardBody>
            <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "col-form-label" }}>Fullname:</H6>
                    <Controller
                      name="fullname"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Fullname"
                          value={field.value || ""}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "col-form-label" }}>Username:</H6>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Username"
                          value={field.value || ""}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <H6 attrH6={{ className: "col-form-label" }}>Email:</H6>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="email"
                      placeholder="Enter email"
                      value={field.value || ""}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <H6 attrH6={{ className: "col-form-label" }}>Password:</H6>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="form-control"
                      type="password"
                      placeholder="Enter password"
                      value={field.value || ""}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup className="row" tag="fieldset">
                <Row>
                  <H6 attrH6={{ className: "col-form-label" }}>Role:</H6>
                  <Col sm="9">
                    <Controller
                      name="role"
                      control={control}
                      defaultValue="admin"
                      render={({ field }) => (
                        <>
                          <div className="radio radio-primary ms-2">
                            <Input
                              type="radio"
                              id="radio1"
                              value="admin"
                              {...field}
                            />
                            <Label for="radio1">Admin</Label>
                          </div>
                          <div className="radio radio-primary ms-2">
                            <Input
                              type="radio"
                              id="radio2"
                              value="staff"
                              {...field}
                            />
                            <Label for="radio2">Staff</Label>
                          </div>
                        </>
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Col>
                <FormGroup className="mb-3">
                  <H6 attrH6={{ className: "col-form-label" }}>
                    Profile Image:
                  </H6>
                  <Col sm="9">
                    <Controller
                      name="image"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageChange(e);
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <CardFooter className="text-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "m-r-15",
                    type: "submit",
                  }}
                >
                  {isLoading ? <TailChase /> : "Save"}
                </Btn>
              </CardFooter>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default NewUserModal;
