import axiosInstance from "../../api/axios";

const IncomeService = {
    getAllIncomes: async () => {
        const response = await axiosInstance.get("/income");
        return response.data;
    },
    addIncome: async (incomeData) => {
        const response = await axiosInstance.post("/income", incomeData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    },
    deleteIncome: async (id) => {
        const response = await axiosInstance.delete(`/income/${id}`);
        return response.data;
    },
    updateIncome: async (id, incomeData) => {
        const response = await axiosInstance.post(`/income/${id}`, incomeData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    },
    downloadFile: async (id) => {
        const response = await axiosInstance.get(`/income/download/${id}`, {
            responseType: 'blob',
        });
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : 'downloaded_file';
        const mimeType = response.headers['content-type'];
        return { data: response.data, fileName, mimeType };
    },

    getProviders: async () => {
        const response = await axiosInstance.get('/providers');
        return response.data.providers;
    }
};

export default IncomeService;