// PinModalContent.jsx

import React, { useState, useEffect, useRef } from "react";
import { Container, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { Btn, P } from "../../AbstractElements";
import customeAxios from "../../api/axios";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import TailChase from "../../Components/Loaders/TailChase";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/images/logo/logo_red.svg";
import logoDark from "../../assets/images/logo/logo_white.svg";
import { useAuth } from "../../_helper/Auth/AuthProvider";

import {Image} from "../../AbstractElements";

const PinModalContent = ({logoClassMain, toggle, layoutURL }) => {
  const { loginOtp, isLoggedIn } = useAuth();
  const { param } = useParams();
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const pinInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // console.log("param", param);


  useEffect(() => {
    if (isLoggedIn) {
      toast.success("OTP sent to your email");
    }
    pinInputRef.current.focus();
  }, [isLoggedIn]);

//   <FormGroup className='mb-4 mt-4'>
//   <span className='reset-password-link'>
//     If don't receive OTP?
//     <a className='btn-link text-danger' href='#javascript'>
//       Resend
//     </a>
//   </span>
// </FormGroup>
// <FormGroup>
//   <Label>Enter OTP</Label>
//   <Row>
//     <Col>
//       <Input className='form-control text-center opt-text' type='text' value='00' maxlength='2' />
//     </Col>
//     <Col>
//       <Input className='form-control text-center opt-text' type='text' value='00' maxlength='2' />
//     </Col>
//     <Col>
//       <Input className='form-control text-center opt-text' type='text' value='00' maxlength='2' />
//     </Col>
//   </Row>
// </FormGroup>

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const { message, success } = await loginOtp(pin, param);
      if (success) {
        toast.success(message);
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        setError(message);
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error); 
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="login-card">
        <div>
        <div>
            <Link
              className={`logo ${logoClassMain ? logoClassMain : ""}`}
              to={process.env.PUBLIC_URL}
            >
              <Image
                attrImage={{
                  className: "img-fluid for-light",
                  src: logoWhite,
                  alt: "looginpage",
                }}
              />
              <Image
                attrImage={{
                  className: "img-fluid for-dark",
                  src: logoDark,
                  alt: "looginpage",
                }}
              />
            </Link>
           </div>
          <div className="login-main">
            <Container fluid className="p-0">
              <Row className="m-0">
                <Col className="p-0">
                  <p attrPara={{ className: "mb-3" }}>
                    This is a security layer to prevent unauthorized accees
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="position-relative mb-3 text-center">
                      <Label className="col-form-label">
                        Enter your 6-digit code sent to your email
                      </Label>
                      <div className="position-relative">
                        <Input
                          innerRef={pinInputRef}
                          className="form-control"
                          type={showPin ? "text" : "password"}
                          name="pin"
                          value={pin}
                          onChange={(e) =>
                            setPin(e.target.value.replace(/\D/, "").slice(0, 6))
                          }
                          pattern="\d*"
                          inputMode="numeric"
                          maxLength={6}
                          minLength={6}
                          required
                          placeholder=""
                          style={{
                            fontSize: "1.8rem",
                            letterSpacing: "14px",
                            textAlign: "center",
                          }}
                        />
                        <div
                          className="position-absolute end-0 top-50  translate-middle-y me-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPin(!showPin)}
                        >
                          {showPin ? (
                            <FiEye style={{ fontSize: "1rem" }} />
                          ) : (
                            <FiEyeOff style={{ fontSize: "1.5rem" }} />
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    {/* {error && <P>{error}</P>} */}
                    <Btn
                      attrBtn={{
                        className: "d-block w-100",
                        color: "primary",
                        type: "submit",
                      }}
                    >
                      {loading ? <TailChase /> : "Submit"}
                    </Btn>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PinModalContent;
