// UserService.js
import axiosInstance from '../../api/axios';

const LabelService = {
  
  register: async (userData) => {
    try {
      const token = localStorage.getItem('authToken');
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axiosInstance.post('/register',  userData );
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Registration failed', error);
      return { success: false, error };
    }
  },

  getAllPartners: async () => {
    try {
      const token = localStorage.getItem('authToken');
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axiosInstance.get('/users/all');
      return { success: true, data: response.data.users };
    } catch (error) {
      console.error('Failed to fetch users', error);
      return { success: false, error };
    }
  },
  //! Duplicated code
  updatePartnerDetails: async (userDetails) => {
    try {
      const token = localStorage.getItem('authToken');
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axiosInstance.post('/users/updateUserByadmin', userDetails);
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Failed to update personal details', error);
      return { success: false, error };
    }
  },
  getLabels: async () => {
    try {
      const token = localStorage.getItem('authToken');
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axiosInstance.get('/users/labels');
      // console.log("from service",response.data.labels);
      return { success: true, data: response.data.labels };
    } catch (error) {
      console.error('Failed to fetch labels', error);
      return { success: false, error };
    }
  },
  

};





export default LabelService;