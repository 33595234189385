// src/Components/Application/Income/UpdateIncomeModal.jsx
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import IncomeService from "../../../Services/income/IncomeService";

const UpdateIncomeModal = ({ isOpen, toggle, onSave, income }) => {
    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            ...income,
            period_start: income?.period_start ? moment(income.period_start).format("YYYY-MM-DD") : "",
            period_end: income?.period_end ? moment(income.period_end).format("YYYY-MM-DD") : ""
        }
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [newFileSelected, setNewFileSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { data: providers, isLoading: isProvidersLoading } = useQuery({
        queryKey: ["providers"],
        queryFn: IncomeService.getProviders,
    });

    useEffect(() => {
        if (income) {
            Object.keys(income).forEach(key => {
                if (key === "period_start" || key === "period_end") {
                    setValue(key, income[key] ? moment(income[key]).format("YYYY-MM-DD") : "");
                } else if (key !== "file") {
                    setValue(key, income[key]);
                }
            });
        }
    }, [income, setValue]);

    useEffect(() => {
        if (!isOpen) {
            setSelectedFile(null);
            setNewFileSelected(false);
            reset();
        }
    }, [isOpen, reset]);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            setSelectedFile(file);
            setNewFileSelected(true);
        } else {
            setSelectedFile(null);
            setNewFileSelected(false);
        }
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key !== "file") {
                formData.append(key, data[key]);
            }
        });
        if (newFileSelected) {
            formData.append("file", selectedFile);
        }
        onSave(formData).then(() => {
            reset();
            setIsLoading(false);
            setNewFileSelected(false);
            setSelectedFile(null);
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Update Income</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="title">Title</Label>
                                <Controller
                                    name="title"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Title is required" }}
                                    render={({ field }) => <Input {...field} type="text" id="title" placeholder="Enter title" invalid={!!errors.title} />}
                                />
                                {errors.title && <div className="text-danger">{errors.title.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Description is required" }}
                                    render={({ field }) => <Input {...field} type="text" id="description" placeholder="Enter description" invalid={!!errors.description} />}
                                />
                                {errors.description && <div className="text-danger">{errors.description.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="provider">Provider</Label>
                                <Controller
                                    name="provider_id"
                                    control={control}
                                    defaultValue={income?.provider_id || ""}
                                    rules={{ required: "Provider is required" }}
                                    render={({ field }) => (
                                        <Input {...field} type="select" id="provider" invalid={!!errors.provider}>
                                            <option value="">Select Provider</option>
                                            {providers && providers.map(provider => (
                                                <option key={provider.id} value={provider.id}>{provider.name}</option>
                                            ))}
                                        </Input>
                                    )}
                                />
                                {errors.provider && <div className="text-danger">{errors.provider.message}</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="amount">Amount</Label>
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Amount is required" }}
                                    render={({ field }) => <Input {...field} type="number" id="amount" placeholder="Enter amount" invalid={!!errors.amount} />}
                                />
                                {errors.amount && <div className="text-danger">{errors.amount.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="currencies">Currency</Label>
                                <Controller
                                    name="currencies"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Currency is required" }}
                                    render={({ field }) => <Input {...field} type="text" id="currencies" placeholder="Enter currency" invalid={!!errors.currencies} />}
                                />
                                {errors.currencies && <div className="text-danger">{errors.currencies.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="status">Status</Label>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Status is required" }}
                                    render={({ field }) => <Input {...field} type="text" id="status" placeholder="Enter status" invalid={!!errors.status} />}
                                />
                                {errors.status && <div className="text-danger">{errors.status.message}</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="period_start">Period Start</Label>
                                <Controller
                                    name="period_start"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Period start is required" }}
                                    render={({ field }) => <Input {...field} type="date" id="period_start" invalid={!!errors.period_start} />}
                                />
                                {errors.period_start && <div className="text-danger">{errors.period_start.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="period_end">Period End</Label>
                                <Controller
                                    name="period_end"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Period end is required" }}
                                    render={({ field }) => <Input {...field} type="date" id="period_end" invalid={!!errors.period_end} />}
                                />
                                {errors.period_end && <div className="text-danger">{errors.period_end.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="file">File</Label>
                                <Input
                                    type="file"
                                    id="file"
                                    onChange={handleFileChange}
                                    invalid={!!errors.file}
                                />
                                {errors.file && <div className="text-danger">{errors.file.message}</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="primary" onClick={handleSubmit(onSubmit)} style={{ margin: "0 auto" }}>
                    {isLoading ? "Loading..." : "Save"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateIncomeModal;