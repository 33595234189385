// AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import AuthService from "../../Services/auth/AuthService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(AuthService.isAuthenticated());
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const login = async (email, password) => {
        setLoading(true);
        const { message, success } = await AuthService.login(email, password);
        if (success) {
            setIsLoggedIn(true);
            setAuthenticated(false);
        } else {
            console.error(message);
        }
        setLoading(false);
        return { message, success };
    };

    const loginOtp = async (user, otp) => {
        setLoading(true);
        const { message, success } = await AuthService.loginOtp(otp, user);
        if (success) {
            await getUser(); // Ensure user data is fetched before setting authenticated
            setAuthenticated(true);
        } else {
            console.error(message);
        }
        setLoading(false);
        return { message, success };
    };

    const getUser = async () => {
        if (!AuthService.isAuthenticated()) {
            setLoading(false);
            return;
        }
        const { success, user, role, error } = await AuthService.getUser();
        if (success) {
            setUserData({ ...user, role });
        } else {
            console.error("Failed to fetch user data", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUser();
    }, []);

    const logout = () => {
        AuthService.logout();
        setIsLoggedIn(false);
        setAuthenticated(false);
        localStorage.removeItem("color1");
        localStorage.removeItem("color2");
        document.documentElement.style.setProperty("--theme-default", '#D51515');
        document.documentElement.style.setProperty("--theme-secondary", '#1d1a1b');
    };

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                login,
                loginOtp,
                isLoggedIn,
                logout,
                setAuthenticated,
                userData,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};