// src/Components/Dashboard/Default/WidgetsWraper.jsx
import React from 'react';
import { Col, Row } from 'reactstrap';
import { WidgetsData, WidgetsData2, WidgetsData3, WidgetsData4, WidgetsData5 } from '../../../Data/DefaultDashboard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';

const WidgetsWrapper = () => {
    return (
        <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Col xl='2' lg='3' md='4' sm='6' xs='12' style={{ flex: '1 0 16.66%' }}>
                <Widgets1 data={WidgetsData} />
            </Col>
            <Col xl='2' lg='3' md='4' sm='6' xs='12' style={{ flex: '1 0 16.66%' }}>
                <Widgets1 data={WidgetsData2} />
            </Col>
            <Col xl='2' lg='3' md='4' sm='6' xs='12' style={{ flex: '1 0 16.66%' }}>
                <Widgets1 data={WidgetsData3} />
            </Col>
            <Col xl='2' lg='3' md='4' sm='6' xs='12' style={{ flex: '1 0 16.66%' }}>
                <Widgets1 data={WidgetsData4} />
            </Col>
            <Col xl='2' lg='3' md='4' sm='6' xs='12' style={{ flex: '1 0 16.66%' }}>
                <Widgets1 data={WidgetsData5} />
            </Col>
        </Row>
    );
};

export default WidgetsWrapper;