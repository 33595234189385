import * as feather from 'feather-icons';
import {FilePlus} from "react-feather";

export const MENUITEMS = {
  admin: [
    {
      menutitle: "General",
      menucontent: "Users,Artists,Products,Expenses,Invoices,Providers",
      Items: [
        {
          title: "Home",
          icon: "home",
          path: `${process.env.PUBLIC_URL}/dashboard`,
          type: "link",
          bookmark: true,
          active: false,
        },
        {
          title: "Users",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/users/users-list`,
          type: "link",
          bookmark: true,
          active: false,
        },
/*         

        {
          title: "Products",
          icon: "project",
          path: `${process.env.PUBLIC_URL}/app/products/products-list`,
          type: "link",
          bookmark: true,
          active: false,
          // children: [
          //   {
          //     path: `${process.env.PUBLIC_URL}/app/products/product-list`,
          //     title: "Products, Assets, Contributors",
          //     type: "link",
          //   },
          // ],
        }, */
        {
          title: "Income",
          icon: "table",
          path: `${process.env.PUBLIC_URL}/app/income/income-list`,
          type: "link",
          bookmark: true,
          active: false,
        },
        {
          title: "Contributors",
          icon: "ui-kits",
          path: `${process.env.PUBLIC_URL}/app/contributors`,
          type: "link",
          bookmark: true,
          active: false,
        },

        {
          title: "Exchange",
          icon: "project",
          path: `${process.env.PUBLIC_URL}/exchange`,
          type: "link",
          bookmark: true,
          active: false,
        },

        // {
        //   title: "Asset",
        //   icon: "social",
        //   path: `${process.env.PUBLIC_URL}/app/asset/asset-list`,
        //   type: "link",
        //   bookmark: true,
        //   active: false,
        //   // children: [
        //   //   {
        //   //     path: `${process.env.PUBLIC_URL}/app/artists/artist-list`,
        //   //     title: "Artists",
        //   //     type: "link",
        //   //   },
        //   // ],
        // },
          /*
        {
          title: "Expenses",
          icon: "landing-page",
          type: "sub",
          bookmark: true,
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/expenses/red-expenses`,
              title: "InHouse Expenses",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/expenses/contributors-expenses`,
              title: "Contributors Expenses",
              type: "link",
            },
          ],
        },
        */
          /*
        {
          title: "Providers",
          icon: "ui-kits",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/providers/fuga`,
              title: "Fuga",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/providers/youtube-api`,
              title: "YouTube API",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/providers/sentric`,
              title: "Sentric",
              type: "link",
            },
          ],
        }

           */
      ],
    },
  ],
  staff: [
    {
      menutitle: "Staff",
      menucontent:
        "Dashboards,Users,Labels,Releases,Transfer,Smart Link,Analitycs",
      Items: [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard`,
          active: false,
        },
        {
          title: "Users",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/users/user-list`,
          type: "sub",
          bookmark: true,
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/users/users-list`,
              type: "link",
              title: "Manage",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/project/new-project`,
              type: "link",
              title: "Permissions",
            },
          ],
        },
        {
          title: "Releases",
          icon: "project",
          type: "sub",

          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/releases/catalog`,
              type: "link",
              title: "Catalog",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/new-catalog`,
              type: "link",
              title: "Build Product",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/import`,
              type: "link",
              title: "ImportRelease",
            },
          ],
        },
        {
          title: "Transfer",
          icon: "button",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/transfer`,
              title: "Create",
              type: "link",
            },
          ],
        },
        {
          title: "Smart Link",
          icon: "button",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/geniuslink`,
              title: "Create",
              type: "link",
            },
          ],
        },
        {
          title: "Analitycs",
          icon: "charts",
          path: `${process.env.PUBLIC_URL}/app/analytics/manage`,
          active: false,
          type: "link",
          children: [
            {
              path: `${process.env.PUBLIC_URL}/charts/apex`,
              type: "link",
              title: "Apex",
            },
            {
              path: `${process.env.PUBLIC_URL}/charts/google`,
              type: "link",
              title: "Google",
            },
            {
              path: `${process.env.PUBLIC_URL}/charts/chartjs`,
              type: "link",
              title: "Chartjs",
            },
          ],
        },
      ],
    },
  ],
};
