import axiosInstance from "../../api/axios";

const ExchangeService = {
    getAllExchangeRates: async () => {
        try {
            const response = await axiosInstance.get("/manual_convert");
            return { success: true, data: response.data.exchangeRates };
        } catch (error) {
            console.error("Failed to fetch exchange rates", error);
            throw error;
        }
    },

    getExchangeRatesByDateRange: async (startDate, endDate) => {
        try {
            const response = await axiosInstance.get(`/manual_convert?start_date=${startDate}&end_date=${endDate}`);
            return { success: true, data: response.data.exchangeRates };
        } catch (error) {
            console.error("Failed to fetch exchange rates by date range", error);
            throw error;
        }
    },

    createExchangeRate: async (formData) => {
        try {
            const response = await axiosInstance.post("/manual_convert", formData);
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Failed to create exchange rate", error);
            throw error;
        }
    },

    updateExchangeRate: async ({ id, formData }) => {
        try {
            const response = await axiosInstance.post(`/manual_convert/${id}`, formData);
            return { success: true, data: response.data };
        } catch (error) {
            console.error(`Failed to update exchange rate with id ${id}`, error);
            throw error;
        }
    },

    deleteExchangeRate: async (id) => {
        try {
            const response = await axiosInstance.delete(`/manual_convert/${id}`);
            return { success: true, data: response.data };
        } catch (error) {
            console.error(`Failed to delete exchange rate with id ${id}`, error);
            throw error;
        }
    },
};

export default ExchangeService;