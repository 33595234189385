// BankDetailsTab.jsx
import React, { useEffect, useContext } from "react";
import {
  Form,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import { Btn, H4 } from "../../../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { Update } from "../../../../../Constant";
import TailChase from "../../../../Loaders/TailChase";
const BankDetailsTab = ({ onBankSubmit, bankDetails, bankDetailsLoading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log("Bank Details:", bankDetails);
  useEffect(() => {
    reset({
      bank_account_number: bankDetails?.bank_account_number || "",
      bank_iban_number: bankDetails?.bank_iban_number || "",
      bank_name: bankDetails?.bank_name || "",
      bank_swift_number: bankDetails?.bank_swift_number || "",
      bank_city: bankDetails?.bank_city || "",
      bank_country: bankDetails?.bank_country || "",
    });
  }, [bankDetails, reset]);
  return (
    <Form className="card theme-form" onSubmit={handleSubmit(onBankSubmit)}>
      <CardHeader>
        <H4 attrH4={{ className: "card-title mb-0" }}>Billing Details</H4>
        <div className="card-options">
          <a className="card-options-collapse" href="#javascript">
            <i className="fe fe-chevron-up"></i>
          </a>
          <a className="card-options-remove" href="#javascript">
            <i className="fe fe-x"></i>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Row>
            {/* 
                  <Col sm="6" md="4">
                    <FormGroup>
                    {" "}
                    <Label className="form-label">{City}</Label>
                      <Controller
                        name="City"
                        control={control}
                        defaultValue={bankDetails.bank_city}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="form-control"
                            type="text"
                            placeholder="City"
                          />
                        )}
                      />
                      <span style={{ color: "red" }}>
                        {errors.City && "City is required"}{" "}
                      </span>
                    </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                    <FormGroup>
                      <Label className="form-label">{PostalCode}</Label>
                      <Controller
                        name="PostalCode"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="form-control"
                            type="number"
                            placeholder="ZIP Code"
                          />
                        )}
                      />
                    </FormGroup>
                    </Col>
                    <Col md="5">
                    <FormGroup>
                      <Label className="form-label">{Country}</Label>
                      <Controller
                        name="Country"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="select"
                            name="select"
                            className="form-control btn-square"
                          >
                            {UsersCountryMenu.map((items, i) => (
                              <option key={i}>{items}</option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  */}
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank Account Number</Label>
                <Controller
                  name="bank_account_number"
                  control={control}
                  defaultValue={bankDetails?.bank_account_number || ""}
                  rules={{
                    required: "Bank Account Number is required",
                    pattern: {
                      value: /^\d{13}$/,
                      message: "Bank Account Number must be a 13 digits",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="number"
                      placeholder="Bank Account Number"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_account_number &&
                    errors.bank_account_number.type === "pattern" &&
                    errors.bank_account_number.message}
                  {errors.bank_account_number &&
                    errors.bank_account_number.type === "required" &&
                    "Bank Account Number is required"}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank IBAN Number</Label>
                <Controller
                  name="bank_iban_number"
                  control={control}
                  defaultValue={bankDetails?.bank_iban_number || ""}
                  rules={{
                    pattern: {
                      value: /^[A-Za-z0-9]{1,34}$/,
                      message:
                        "Bank IBAN Number must be an alphanumeric string of up to 34 characters",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="text"
                      placeholder="Bank IBAN Number"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_iban_number && errors.bank_iban_number.message}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank Name</Label>
                <Controller
                  name="bank_name"
                  control={control}
                  defaultValue={bankDetails?.bank_name || ""}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="text"
                      placeholder="Bank Name"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_name && "Bank Name is required"}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank Swift Number</Label>
                <Controller
                  name="bank_swift_number"
                  control={control}
                  defaultValue={bankDetails?.bank_swift_number || ""}
                  // rules={{ required: true }}
                  rules={{
                    required: "Bank Swift Number is required",
                    pattern: {
                      value: /^\d{4}$/,
                      message: "Bank Swift Number must be a 4-digit number",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="number"
                      placeholder="Bank Swift Number"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_swift_number &&
                    errors.bank_swift_number.type === "pattern" &&
                    errors.bank_swift_number.message}
                  {errors.bank_swift_number &&
                    errors.bank_swift_number.type === "required" &&
                    "Bank Swift Number is required"}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank City</Label>
                <Controller
                  name="bank_city"
                  control={control}
                  defaultValue={bankDetails?.bank_city || ""}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="text"
                      placeholder="Bank City"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_city && "Bank City is required"}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">Bank Country</Label>
                <Controller
                  name="bank_country"
                  control={control}
                  defaultValue={bankDetails?.bank_country || ""}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="form-control"
                      type="text"
                      placeholder="Bank Country"
                    />
                  )}
                />
                <span style={{ color: "red" }}>
                  {errors.bank_country && "Bank Country is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>
        </Row>
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <Btn
          attrBtn={{
            color: "primary",
            type: "submit",
            disabled: bankDetailsLoading,
          }}
        >
          {bankDetailsLoading ? <TailChase /> : `${Update} Banking Info`}
        </Btn>
      </CardFooter>
    </Form>
  );
};

export default BankDetailsTab;
