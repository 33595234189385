import React from "react";
import { tailChase } from "ldrs";
export default function TailChase() {
  tailChase.register();
  return (
    <>
      <l-tail-chase size="24" speed="1.80" color="white"></l-tail-chase>
    </>
  );
}


