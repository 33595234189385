import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, CardFooter, Button } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import IncomeService from "../../../Services/income/IncomeService";
import { toast } from "react-toastify";

function IncomeStepOne({ handleNext, setIncomeId }) {
  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [newFileSelected, setNewFileSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { data: providers, isLoading: isProvidersLoading } = useQuery({
    queryKey: ["providers"],
    queryFn: IncomeService.getProviders,
  });

  const addIncomeMutation = useMutation({
    mutationFn: IncomeService.addIncome,
    onSuccess: (data) => {
      toast.success("Income added successfully!");
      queryClient.invalidateQueries(["incomes"]);
      reset();
      setIsLoading(false);
      setNewFileSelected(false);
      setSelectedFile(null);
      setIncomeId(data.data.id); // Ensure the correct path to the ID
      handleNext();
    },
    onError: (error) => {
      toast.error(`Failed to add income: ${error.message}`);
      setIsLoading(false);
    },
  });

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setSelectedFile(file);
      setNewFileSelected(true);
    } else {
      setSelectedFile(null);
      setNewFileSelected(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    });
    if (newFileSelected) {
      formData.append("file", selectedFile);
    }
    addIncomeMutation.mutate(formData);
  };

  return (
      <>
        <Col sm="12">
          <Card className="card-absolute">
            <CardHeader className="bg-primary">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <H5>Income Details</H5>
              </div>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Title is required" }}
                          render={({ field }) => <Input {...field} type="text" id="title" placeholder="Enter title" invalid={!!errors.title} />}
                      />
                      {errors.title && <div className="text-danger">{errors.title.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Description is required" }}
                          render={({ field }) => <Input {...field} type="text" id="description" placeholder="Enter description" invalid={!!errors.description} />}
                      />
                      {errors.description && <div className="text-danger">{errors.description.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="provider">Provider</Label>
                      <Controller
                          name="provider_id"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Provider is required" }}
                          render={({ field }) => (
                              <Input {...field} type="select" id="provider" invalid={!!errors.provider_id}>
                                <option value="">Select Provider</option>
                                {providers && providers.map(provider => (
                                    <option key={provider.id} value={provider.id}>{provider.name}</option>
                                ))}
                              </Input>
                          )}
                      />
                      {errors.provider_id && <div className="text-danger">{errors.provider_id.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="amount">Amount</Label>
                      <Controller
                          name="amount"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Amount is required" }}
                          render={({ field }) => <Input {...field} type="number" id="amount" placeholder="Enter amount" invalid={!!errors.amount} />}
                      />
                      {errors.amount && <div className="text-danger">{errors.amount.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="currencies">Currency</Label>
                      <Controller
                          name="currencies"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Currency is required" }}
                          render={({ field }) => <Input {...field} type="text" id="currencies" placeholder="Enter currency" invalid={!!errors.currencies} />}
                      />
                      {errors.currencies && <div className="text-danger">{errors.currencies.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="status">Status</Label>
                      <Controller
                          name="status"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Status is required" }}
                          render={({ field }) => <Input {...field} type="text" id="status" placeholder="Enter status" invalid={!!errors.status} />}
                      />
                      {errors.status && <div className="text-danger">{errors.status.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="period_start">Period Start</Label>
                      <Controller
                          name="period_start"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Period start is required" }}
                          render={({ field }) => <Input {...field} type="date" id="period_start" invalid={!!errors.period_start} />}
                      />
                      {errors.period_start && <div className="text-danger">{errors.period_start.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="period_end">Period End</Label>
                      <Controller
                          name="period_end"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Period end is required" }}
                          render={({ field }) => <Input {...field} type="date" id="period_end" invalid={!!errors.period_end} />}
                      />
                      {errors.period_end && <div className="text-danger">{errors.period_end.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="file">File</Label>
                      <Controller
                          name="file"
                          control={control}
                          rules={{ required: "File is required" }}
                          render={({ field }) => (
                              <Input {...field} type="file" id="file" onChange={(e) => { handleFileChange(e); field.onChange(e); }} invalid={!!errors.file} />
                          )}
                      />
                      {errors.file && <div className="text-danger">{errors.file.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <CardFooter className="text-end">
                  <Btn
                      attrBtn={{
                        color: "primary",
                        className: "m-r-15",
                        type: "submit",
                        disabled: isLoading,
                      }}
                  >
                    {isLoading ? "Submitting..." : "Next Step"}
                  </Btn>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
  );
}

export default IncomeStepOne;