import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import { Btn, H6, H5 } from "../../../../AbstractElements";
import { XCircle, PlusCircle } from "react-feather";
import TailChase from "../../../Loaders/TailChase";

const NewContributorModal = ({ isOpen, toggle, onSave, royalties }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contributor_name: "",
      contributor_role: "",
      royalty_ids: [{ id: null, percentage: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "royalty_ids",
  });

  const [availableRoyalties, setAvailableRoyalties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const deleteRoyalty = (index) => {
    const deletedRoyalty = fields[index];
    remove(index);

    const correspondingRoyalty = royalties.find(
      (r) => r.name === deletedRoyalty.name
    );

    if (correspondingRoyalty) {
      setAvailableRoyalties((prevAvailableRoyalties) => [
        ...prevAvailableRoyalties,
        correspondingRoyalty,
      ]);
    }
  };

  const addRoyalty = (royalty) => {
    append(royalty);
    setAvailableRoyalties(
      availableRoyalties.filter((r) => r.id !== royalty.id)
    );
  };

  const addRoyaltyForm = () => {
    addRoyalty({ id: null, percentage: "" });
  };

  const handleNewContributor = handleSubmit((data) => {
    // console.log(data);
    setIsLoading(true);
    onSave(data).finally(() => {
      setIsLoading(false);
    });
  });

  useEffect(() => {
    setAvailableRoyalties(royalties);
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>New Contributor Form</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form
              className="theme-form"
              onSubmit={handleSubmit(handleNewContributor)}
            >
              <Row>
                <FormGroup className="mb-3 col-6">
                  <H6 attrH6={{ className: "col-form-label" }}>
                    Contributor Name
                  </H6>
                  <Controller
                    name="contributor_name"
                    control={control}
                    rules={{ required: "Contributor name is required" }}
                    render={({ field }) => (
                      <>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Enter contributor name"
                          {...field}
                        />
                        {errors.contributor_name && (
                          <span className="text-danger">
                            {errors.contributor_name.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <H6 attrH6={{ className: "col-form-label" }}>
                    Contributor Role
                  </H6>
                  <Controller
                    name="contributor_role"
                    control={control}
                    rules={{ required: "Contributor role is required" }}
                    render={({ field }) => (
                      <>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Enter contributor role"
                          {...field}
                        />
                        {errors.contributor_role && (
                          <span className="text-danger">
                            {errors.contributor_role.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </FormGroup>
              </Row>
              <hr />

              <div
                className="col-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <H5 attrH6={{ className: "col-form-label" }}>Royalties</H5>
                <Btn
                  attrBtn={{
                    color: "success",
                    className: "p-r-30",
                    type: "button",
                    style: {
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      padding: "0",
                    },
                    onClick: addRoyaltyForm,
                  }}
                >
                  <PlusCircle />
                </Btn>
              </div>

              {fields.map((royalty, i) => (
                <FormGroup key={i} className="mb-3 col-10">
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Controller
                        name={`royalty_ids[${i}].id`}
                        control={control}
                        rules={{ required: "Royalty selection is required" }}
                        defaultValue={royalty.id}
                        render={({ field }) => (
                          <>
                            <input type="hidden" {...field} />
                            <Input
                              className="form-control form-control-primary btn-square"
                              type="select"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                const selectedRoyalty = availableRoyalties.find(
                                  (r) => r.id === parseInt(e.target.value)
                                );
                                if (selectedRoyalty) {
                                  setValue(
                                    `royalty_ids[${i}].id`,
                                    selectedRoyalty.id
                                  );
                                }
                              }}
                            >
                              <option value="">Select a royalty</option>
                              {availableRoyalties.map((r) => (
                                <option key={r.id} value={r.id}>
                                  {r.name}
                                </option>
                              ))}
                            </Input>
                            {errors.royalty_ids?.[i]?.id && (
                              <span className="text-danger">
                                {errors.royalty_ids[i].id.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                      <div style={{ width: "15px" }}></div>
                      <Controller
                        name={`royalty_ids[${i}].percentage`}
                        control={control}
                        rules={{ required: "Percentage is required" }}
                        defaultValue={royalty.percentage}
                        render={({ field }) => (
                          <>
                            <Input
                              className="form-control"
                              type="number"
                              placeholder="Enter percentage"
                              style={{ marginRight: "15px" }}
                              {...field}
                            />
                            {errors.royalty_ids?.[i]?.percentage && (
                              <span className="text-danger">
                                {errors.royalty_ids[i].percentage.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "p-r-30 ",
                          type: "button",
                          style: {
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          },
                          onClick: (e) => {
                            deleteRoyalty(i);
                          },
                        }}
                      >
                        <XCircle />
                      </Btn>
                    </div>
                  </>
                </FormGroup>
              ))}

              <div style={{ textAlign: "right" }}>
                <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    className: "mt-3",
                  }}
                >
                  {isLoading ? <TailChase /> : "Save"}
                </Btn>
              </div>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default NewContributorModal;
