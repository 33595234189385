import React from "react";
import { grid } from "ldrs";

export default function GridLoader() {
  grid.register();
  const color= localStorage.getItem("color1") || "#D51515";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      
      <l-grid size="150" speed="1.5" color={color}></l-grid>
    </div>
  );
}
