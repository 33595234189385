import React, {useState} from 'react'
import { Breadcrumbs, Btn, H6 } from "../../../AbstractElements";
import DataTable from 'react-data-table-component'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input, Badge,
} from "reactstrap";

//// BreadCrumbs
//// Data : Invoice number, start_date, end_date , amount, currency, status, file , created_at , updated_at
//// Dummy Data and Table Columns
//// ReactData Table 
//// Actions Update Delete

function InvoicesTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");

  const generateRandomData = () => {
    const data = [];
    const statuses = ["Paid", "Unpaid"];
    const currencies = ["USD", "EUR", "GBP"];
    const files = ["File1", "File2", "File3"];
  
    for (let i = 0; i < 10; i++) {
      const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
      const randomCurrency = currencies[Math.floor(Math.random() * currencies.length)];
      const randomFile = files[Math.floor(Math.random() * files.length)];
  
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - Math.floor(Math.random() * 30));
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + Math.floor(Math.random() * 30));
  
      data.push({
        invoice_number: Math.floor(Math.random() * 1000000),
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        amount: (Math.random() * 1000).toFixed(2),
        currency: randomCurrency,
        status: randomStatus,
        file: randomFile,
        created_at: new Date().toISOString().split('T')[0],
        updated_at: new Date().toISOString().split('T')[0],
      });
    }
  
    return data;
  };
  const filteredData = generateRandomData();

  const customStyles = {
    rows: {
        style: {
            margin: '0', 
            padding: '0.5rem 0', 
        },
    },
    cells: {
        style: {
            padding: '0.5rem',
        },
    },
};
  const tableColumns = [
    {
      name: "Invoice Number",
      selector: (row) => row["invoice_number"],
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => row["start_date"],
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => row["end_date"],
      sortable: true,
      center: true,
    },
    {
      name: "Amount",
      selector: (row) => row["amount"],
      sortable: true,
      center: true,
    },
    {
      name: "Currency",
      selector: (row) => row["currency"],
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: true,
    },
    {
      name: "File",
      selector: (row) => row["file"],
      sortable: true,
      center: true,
    },
    {
      name: "Created At",
      selector: (row) => row["created_at"],
      sortable: true,
      center: true,
    },
    {
      name: "Updated At",
      selector: (row) => row["updated_at"],
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container todo-list-footer">
                <ul className="simple-list">
                  <li className="task border-0">
                    <div className="task-container">
                    <span className="task-action-btn">
                      <span
                          className="action-box large icon-edit"
                          title="Update"
                          onClick={() => handleUpdateInvoice(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                          className="action-box large delete-btn"
                          title="Delete"
                          onClick={() => handleDeleteInvoice(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      ),
      center: true,
    },

  ];

  const handleUpdateInvoice = (row) => {
    console.log("Update Invoice", row);
  };
  const handleDeleteInvoice = (id) => {
    console.log("Delete Invoice", id);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
}; 
  return (
    <>
    <Breadcrumbs
      parent="Invoices"
      title="Invoices"
      mainTitle="Invoices List"
      buttons={[
        // <Btn
        //   attrBtn={{
        //     size: "sm",
        //     color: "primary",
        //     // onClick: handleSyncInvoices, // Replace with your function
        //   }}
        // >
        //   <i className="fa fa-refresh"></i> Sync Invoices
        // </Btn>,
      ]}
    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5>Invoices Table</h5>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    type="text"
                    placeholder="Search by invoice number or date"
                    value={searchTerm} 
                    className="form-control"
                    onChange={handleSearch} 
                    style={{ width: "400px", marginRight: "10px" }}
                  />
                  <Input
                    type="select"
                    value={filter} 
                    onChange={handleFilterChange} 
                    style={{ width: "150px", marginRight: "10px" }}
                  >
                    <option value="All">All</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </Input>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={tableColumns}
                data={filteredData} 
                striped={true}
                center={true}
                responsive={true}
                pagination
                customStyles={customStyles} 
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
  )
}

export default InvoicesTable