// SecurityInfoTab.jsx
import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Media,
  Spinner,
} from "reactstrap";
import { Btn, H4, H6 } from "../../../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { Eye, EyeOff } from "react-feather";
import { useAuth } from "../../../../../_helper/Auth/AuthProvider";
import { FiEye, FiEyeOff } from "react-icons/fi";
const SecurityInfoTab = ({ onSecuritySubmit, loading , securitySettings }) => {
  // const { updateSecurityLayers, securitySettings } = useAuth();
  console.log("security",securitySettings)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [togglePassword, setTogglePassword] = useState(false);
  const [showPin, setShowPin] = useState(false);

 
  const toggleShowPassword = () => {
    setTogglePassword(!togglePassword);
  };

  useEffect(() => {
    setValue("isPinOTP", securitySettings && securitySettings.isPinOTP === 1);
  }, [securitySettings, setValue]);

  const isPinOTP = watch("isPinOTP");

  return (
    <Form className="card theme-form" onSubmit={handleSubmit(onSecuritySubmit)}>
      <Card>
        <CardHeader>
          <H4 attrH4={{ className: "card-title mb-0" }}>Security Info</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6" md="4">
              <FormGroup className="position-relative">
                <Label for="newPassword" className="col-form-label m-0">
                  New Password
                </Label>
                <div className="position-relative">
                  <Controller
                    name="newPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        type={togglePassword ? "text" : "password"}
                        id="newPassword"
                        placeholder="*********"
                        className="form-control"
                      />
                    )}
                  />
                  <div
                    className="position-absolute end-0 top-50  translate-middle-y me-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    {togglePassword ? (
                      <FiEye size={15} style={{ fontSize: "1rem" }} />
                    ) : (
                      <FiEyeOff style={{ fontSize: "1.5rem" }} size={15} />
                    )}
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup className="position-relative">
                <Label for="confirmPassword" className="col-form-label m-0">
                  Confirm Password
                </Label>
                <div className="position-relative">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: (value) =>
                        value === watch("newPassword") ||
                        "Passwords do not match",
                    }}
                    render={({ field }) => (
                      <div>
                        <Input
                          {...field}
                          type={togglePassword ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="*********"
                          className="form-control"
                        />
                        {errors.confirmPassword && (
                          <span style={{ color: "red" }}>
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                  <div
                    className="position-absolute end-0 top-50  translate-middle-y me-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    {togglePassword ? (
                      <FiEye size={15} style={{ fontSize: "1rem" }} />
                    ) : (
                      <FiEyeOff size={15} style={{ fontSize: "1.5rem" }} />
                    )}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          {/*SMS */}
          <Row>
            <Col sm="6">
              <Media>
                <Label className="col-form-label m-r-22 ">
                  {" "}
                  <H6> Enable SMS Method :</H6>{" "}
                </Label>
                <Media body className="text-end icon-state ">
                  <Label className="switch">
                    <Controller
                      name="isSmsOTP"
                      control={control}
                      defaultValue={
                        securitySettings && securitySettings.isSmsOTP === 1
                      }
                      render={({ field }) => (
                        <Input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />
                    <span className="switch-state bg-primary"></span>
                  </Label>
                </Media>
              </Media>
            </Col>
          </Row>
          <hr />
          {/*MAIL */}
          <Row>
            <Col sm="6">
              <Media>
                <Label className="col-form-label m-r-22 ">
                  {" "}
                  <H6> Enable MAIL Method :</H6>{" "}
                </Label>
                <Media body className="text-end icon-state ">
                  <Label className="switch">
                    <Controller
                      name="isEmailOTP"
                      control={control}
                      defaultValue={
                        securitySettings && securitySettings.isEmailOTP === 1
                      }
                      render={({ field }) => (
                        <Input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />
                    <span className="switch-state bg-primary"></span>
                  </Label>
                </Media>
              </Media>
            </Col>
          </Row>
          <hr />
          {/*PIN */}
          <Row>
            <Col sm="6">
              <Media>
                <Label className="col-form-label m-r-22 ">
                  {" "}
                  <H6> Enable PIN Method :</H6>{" "}
                </Label>
                <Media body className="text-end icon-state ">
                  <Label className="switch">
                    <Controller
                      name="isPinOTP"
                      control={control}
                      defaultValue={
                        securitySettings && securitySettings.isPinOTP === 1
                      }
                      render={({ field }) => (
                        <Input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />
                    <span className="switch-state bg-primary"></span>
                  </Label>
                </Media>
              </Media>
            </Col>
            <Col sm="6">
              {isPinOTP && (
                <FormGroup className="position-relative mb-3 text-center">
                  <Label className="col-form-label">
                    Enter your 6-digit PIN
                  </Label>
                  <div className="position-relative">
                    <Controller
                      name="pinValue"
                      control={control}
                      defaultValue={securitySettings?.pin || ""}
                      rules={{
                        required: true,
                        minLength: 6,
                        maxLength: 6,
                        pattern: /\d*/,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type={showPin ? "text" : "password"}
                          className="form-control"
                          placeholder=""
                          style={{
                            fontSize: "1.8rem",
                            letterSpacing: "14px",
                            textAlign: "center",
                          }}
                          onChange={(e) => {
                            field.onChange(
                              e.target.value.replace(/\D/, "").slice(0, 6)
                            );
                          }}
                        />
                      )}
                    />
                    <div
                      className="position-absolute end-0 top-50 translate-middle-y me-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPin(!showPin)}
                    >
                      {showPin ? (
                        <FiEye style={{ fontSize: "1rem" }} />
                      ) : (
                        <FiEyeOff style={{ fontSize: "1.5rem" }} />
                      )}
                    </div>
                  </div>
                </FormGroup>
              )}
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="d-flex justify-content-center">
          <Btn attrBtn={{ color: "primary", type: "submit" }}>
            {loading ? <Spinner size="sm" color="light" /> : "Save Changes"}
          </Btn>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default SecurityInfoTab;
