import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import EditMyProfile from "./EditmyProfile";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import ProfileSideBar from "./ProfileSideBar";
import useBankDetailsStore from "../../../../_helper/User/bankDetailsStore";
import useSecuritySettingsStore from "../../../../_helper/User/secuitySettingsStore";
import GridLoader from "../../../Loaders/GridLoader";

// Default values shown
const UsersEditContain = () => {
  const { userData,setUserData,loading } = useAuth();
  const {
    bankDetails,
    getUserBankDetails,
    setBankDetails,
    bankDetailsLoading,
    addBankDetails,
  } = useBankDetailsStore();

  const { securitySettings, fetchSecuritySettings } = useSecuritySettingsStore(); 

  // console.log("User Data:", bankDetails);
  // console.log("User Data:", securitySettings);


  useEffect(() => {
    getUserBankDetails();
    fetchSecuritySettings();
  }, []);

  //todo:combine in a single return
  if (loading || bankDetailsLoading) {
    return <GridLoader />;
  }

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Edit Profile"
        parent="Users"
        title="Edit Profile"
      />
      <Container fluid={true} className="email-wrap bookmark-wrap todo-wrap">
        <Row>
          <ProfileSideBar />
          <Col xl="8">
            <EditMyProfile
              userData={userData}
              setUserData={setUserData}
              bankDetails={bankDetails}
              // getUserBankDetails={getUserBankDetails}
              bankDetailsLoading={bankDetailsLoading}
              setBankDetails={setBankDetails}
              addBankDetails={addBankDetails}
              securitySettings={securitySettings}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UsersEditContain;
