import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ProductService from "../../../../Services/products/ProductService";
import DataTable from "react-data-table-component";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Input, Badge,
} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import SpinnerLoader from "../../../Loaders/Spinner";
function AssetContainer() {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["product", id],
    queryFn: () => ProductService.getProductById(id),
  });
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.data.assets.filter(
    (asset) =>
      (filter === "All" ||
        (filter === "Active" && asset.takedown_date === true) ||
        (filter === "Unactive" && asset.takedown_date === false)) &&
      (asset.asset_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.ISRC.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleNavBack = () => {
    window.history.back();
  };

  const tableColumns = [
    {
      name: "Asset Name",
      cell: (row) => (
        <Link
          to={`${process.env.PUBLIC_URL}/app/products/${id}/assets/${row.id}/contributors`}
        >
          {row["asset_name"]}
        </Link>
      ),
        width: "200px",
    },
    {
      name: "ISRC",
      selector: (row) => row["ISRC"],
      width: "250px",
    },
    {
      name: "Release Date",
      selector: (row) => row["release_date"],
        width: "250px",
    },
      {
          name: "Takedown Date",
          selector: (row) => (row["takedown_date"] ?  row["takedown_date"] : "N/A" ),
          width: "250px",
      },
      {
          name: "Contributors",
          cell: (row) => (
              <div>
                  {row["contributors"].map((contributor, index) => (
                      <Badge key={index} color="primary" className="mr-1">
                          {contributor.contributor_name}
                      </Badge>
                  ))}
              </div>
          ),
          sortable: true,
      },
  ];

  if (isError) return <div>Error loading assets: {error.message}</div>;

  return (
    <>
      <Breadcrumbs
        parent="Products"
        title="Assets"
        mainTitle="Assets"
        buttons={[
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              // outline: true,
              onClick: handleNavBack,
            }}
          >
          <i class="fa fa-arrow-left"></i>  Go Back
          </Btn>,
        ]}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>Assets Table</h5>
                  <Input
                    type="text"
                    placeholder="Search by asset name or ISRC"
                    value={searchTerm}
                    className="form-control"
                    onChange={handleSearch}
                    style={{ width: "400px" }}
                  />
                </div>
              </CardHeader>
              <CardBody>
                {isPending ? (
                  <SpinnerLoader />
                ) : (
                  <DataTable
                    columns={tableColumns}
                    data={filteredData}
                    striped={true}
                    center={true}
                    responsive={true}
                    pagination
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AssetContainer;
