// src/Components/Application/Contributors/index.jsx
import React, { useState } from "react";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Container, Row, Col, Card, CardBody, CardHeader, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, FormFeedback } from "reactstrap";
import DataTable from 'react-data-table-component';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ContributorService from "../../../Services/contributors/ContributorService";
import { toast } from "react-toastify";
import SpinnerLoader from "../../Loaders/Spinner";
import moment from "moment";

function ContributorContainer() {
    const [searchTerm, setSearchTerm] = useState("");
    const [modal, setModal] = useState(false);
    const [selectedContributor, setSelectedContributor] = useState(null);
    const [errors, setErrors] = useState({});
    const queryClient = useQueryClient();

    const { isLoading, isError, data, error } = useQuery({
        queryKey: ["contributors"],
        queryFn: ContributorService.getAllContributors,
    });

    const updateMutation = useMutation({
        mutationFn: ContributorService.updateContributor,
        onSuccess: () => {
            queryClient.invalidateQueries("contributors");
            toast.success("Contributor updated successfully");
            setModal(false);
        },
        onError: (error) => {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                toast.error(`Failed to update contributor: ${error.message}`);
            }
        },
    });

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleUpdateContributor = (contributor) => {
        setSelectedContributor(contributor);
        setErrors({});
        setModal(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = {
            contributor_name: event.target.contributor_name.value,
            contributor_role: event.target.contributor_role.value,
            bank_account_number: event.target.bank_account_number.value,
            bank_iban_number: event.target.bank_iban_number.value,
            bank_swift_number: event.target.bank_swift_number.value,
            bank_name: event.target.bank_name.value,
            ca_ipi: event.target.ca_ipi.value,
            currency: event.target.currency.value,
        };
        updateMutation.mutate({ id: selectedContributor.id, formData });
    };

    const tableColumns = [
        {
            name: "Contributor Name",
            selector: (row) => row["contributor_name"],
            sortable: true,
        },
        {
            name: "Role",
            selector: (row) => row["contributor_role"],
            sortable: true,
        },
        {
            name: "Bank Account Number",
            selector: (row) => row["bank_account_number"],
            sortable: true,
        },
        {
            name: "Bank IBAN Number",
            selector: (row) => row["bank_iban_number"],
            sortable: true,
        },
        {
            name: "Bank SWIFT Number",
            selector: (row) => row["bank_swift_number"],
            sortable: true,
        },
        {
            name: "Bank Name",
            selector: (row) => row["bank_name"],
            sortable: true,
        },
        {
            name: "Currency",
            selector: (row) => row["currency"] ?? "N/A",
            sortable: true,
        },
        {
            name: "CA IPI",
            selector: (row) => row["ca_ipi"],
            sortable: true,
        },
        {
            name: "Created At",
            selector: (row) => moment(row["created_at"]).format("YYYY-MM-DD"),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="todo">
                    <div className="todo-list-wrapper">
                        <div className="todo-list-container todo-list-footer">
                            <ul className="simple-list">
                                <li className="task border-0">
                                    <div className="task-container">
                                        <span className="task-action-btn">
                                            <span
                                                className="action-box large icon-edit"
                                                title="Update"
                                                onClick={() => handleUpdateContributor(row)}
                                            >
                                                <i className="icon">
                                                    <i className="fa fa-edit"></i>
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ),
            center: true,
        },
    ];

    if (isLoading) return <SpinnerLoader />;
    if (isError) return <div>Error loading contributors: {error.message}</div>;

    const filteredData = data.data.filter(contributor =>
        contributor.contributor_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.contributor_role.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.bank_account_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.bank_iban_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.bank_swift_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.bank_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contributor.ca_ipi.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Breadcrumbs
                parent="Contributors"
                title="Contributors"
                mainTitle="Contributors List"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h5>Contributors Table</h5>
                                    <Input
                                        type="text"
                                        placeholder="Search by any field"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        style={{ width: '400px' }}
                                    />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={tableColumns}
                                    data={filteredData}
                                    striped={true}
                                    center={true}
                                    responsive={true}
                                    pagination
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
                <ModalHeader toggle={() => setModal(!modal)}>Update Contributor</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="contributor_name">Name</Label>
                                    <Input type="text" name="contributor_name" id="contributor_name" defaultValue={selectedContributor?.contributor_name} invalid={!!errors.contributor_name} required />
                                    {errors.contributor_name && <FormFeedback>{errors.contributor_name[0]}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="contributor_role">Role</Label>
                                    <Input type="text" name="contributor_role" id="contributor_role" defaultValue={selectedContributor?.contributor_role} invalid={!!errors.contributor_role} required />
                                    {errors.contributor_role && <FormFeedback>{errors.contributor_role[0]}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="bank_account_number">Bank Account Number</Label>
                                    <Input type="text" name="bank_account_number" id="bank_account_number" defaultValue={selectedContributor?.bank_account_number} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="bank_iban_number">Bank IBAN Number</Label>
                                    <Input type="text" name="bank_iban_number" id="bank_iban_number" defaultValue={selectedContributor?.bank_iban_number} required />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="bank_swift_number">Bank SWIFT Number</Label>
                                    <Input type="text" name="bank_swift_number" id="bank_swift_number" defaultValue={selectedContributor?.bank_swift_number} required />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="bank_name">Bank Name</Label>
                                    <Input type="text" name="bank_name" id="bank_name" defaultValue={selectedContributor?.bank_name} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="ca_ipi">CA IPI</Label>
                                    <Input type="text" name="ca_ipi" id="ca_ipi" defaultValue={selectedContributor?.ca_ipi} required />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="currency">Currency</Label>
                                    <Input type="text" name="currency" id="currency" defaultValue={selectedContributor?.currency} invalid={!!errors.currency} required />
                                    {errors.currency && <FormFeedback>{errors.currency[0]}</FormFeedback>}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="text-center">
                        <Button type="submit" color="primary">Update</Button>
                        <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default ContributorContainer;