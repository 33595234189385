import axiosInstance from "../../api/axios";

const ExpenseService = {
    getInhouseExpenses: async () => {
        try {
          const response = await axiosInstance.get("/expenses/inhouse");
          if (!response.data.success) {
            throw new Error('Failed to fetch inhouse expenses');
          }
          return { success: true, data: response.data.data };
        } catch (error) {
          console.error("Failed to fetch inhouse expenses", error);
          return { success: false, error };
        }
      },
  getInhouseCategories: async () => {
    try {
      const response = await axiosInstance.get("/categories/inhouse");
      if (!response.data.success) {
        throw new Error('Failed to fetch inhouse categories');
      }
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch inhouse categories", error);
      return { success: false, error };
    }
  },

  getContributorCategories: async () => {
    try {
      const response = await axiosInstance.get("/categories/contributors");
      if (!response.data.success) {
        throw new Error('Failed to fetch contributor categories');
      }
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch contributor categories", error);
      return { success: false, error };
    }
  },

  createExpense: async (formData) => {
    try {
      const response = await axiosInstance.post("/expenses", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        throw new Error('Failed to create expense');
      }

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to create expense", error);
      return { success: false, error };
    }
  },

  updateExpense: async (id, formData) => {
    try {
      const response = await axiosInstance.post(`/expenses/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        throw new Error('Failed to update expense');
      }

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to update expense", error);
      return { success: false, error };
    }
  },
  downloadExpenseFile: async (id) => {
    try {
      const response = await axiosInstance.get(`/expenses/${id}/download`, {
        responseType: 'blob',
      });

      if (!response.status === 200) {
        throw new Error('Failed to download expense');
      }

      return new Blob([response.data], { type: response.headers['content-type'] });
    } catch (error) {
      console.error("Failed to download expense", error);
      throw error;
    }
  },
};

export default ExpenseService;