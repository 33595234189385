import React, { Fragment, useContext } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <Fragment>
      <Container fluid={true}>
        <div className='page-title'>
          <Row className='align-items-center'>
            <Col xs='12' md='4'>
              <H3>{props.mainTitle}</H3>
            </Col>
            <Col xs='12' md='8'>
              <div className='d-flex justify-content-md-end align-items-center'>
                {props.buttons && props.buttons.map((button, index) => (
                  <div key={index} className='me-md-2' style={{ whiteSpace: 'nowrap' }}>
                    {button}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
