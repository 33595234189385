import React from "react";
import { tailChase } from "ldrs";
export default function SpinnerLoader() {
  tailChase.register();
  return (
    <>
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
        }}
    >
        <l-tail-chase size="40" speed="1.80" color="red"></l-tail-chase>
    </div>
    </>
  );
}


