import { set } from 'date-fns';
import Context from './index';
import React, { useEffect, useState } from 'react';

const FilterProvider = (props) => {
    const [brand, setBrand] = useState(['Diesel', 'Hudson', 'Lee']);
    //const [genre, setGenre] = useState(['Jazz', 'Flamenco', 'Hip-Hop', 'Drill']);
    const [type, setType] = useState(['Album', 'Single']);
    const [status, setStatus] = useState(['Approved', 'Delivered', 'Takedown', 'Draft']);
    const [explicit, setExplicit] = useState(['Explicit', 'No Explicit']);
    const [regions, setRegions] = useState(['Africa', 'Oceania', 'Polar', 'Europe', 'America', 'Asia' ,'Worldwide']);
    const [value, setValue] = useState({ min: 100, max: 950 });
    const [sortBy, setSortBy] = useState('');
    const [category, setCategory] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [colors, setColor] = useState('');
    const [filterContext, setFilterContext] = useState(false);
    // const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filter, setFilter] = useState({
        //genre: genre,
        status: status,
        brand: brand,
        color: colors,
        explicit: explicit,
        value: value,
        sortBy: sortBy,
        searchBy: searchBy,
        category: category,
    });
    
    useEffect(() => {
    }, [setBrand, setValue, setSortBy, setCategory, setColor, setFilter, setSearchBy, setStatus, setRegions, setExplicit]);

    const filterCategory = (category) => {
        setCategory(category);
        setFilter((prev) => ({ ...prev, category: category }));
    };

    const filterBrand = (brand) => {
        setBrand(brand);
        setFilter((prev) => ({ ...prev, brand: brand }));
    };

/*     const filterGenre = (selectedGenres) => {
        setFilterContext(true); 
        setFilter((prev) => ({ ...prev, genre: selectedGenres }));
      }; */
      
      const filterExplicit = (explicit) => {
        setFilterContext(true); 
        // setExplicit(explicit);
        setFilter((prev) => ({ ...prev, explicit: explicit }));
        
      };

      const filterStatus = (selectedStatus) => {
        setFilterContext(true);
        // setSelectedStatus(status);
        setFilter((prev) => ({ ...prev, status: selectedStatus }));        
      };

/*       const filterRegions  = (regions) => {
        setRegions(regions);
        setFilter((prev) => ({ ...prev, regions: regions }));
      } */

      const clearFilter = () => {
        setFilter({ status: null});
        setFilterContext(false);
      };

    const filterColor = (color) => {
        setColor(color);
        setFilter((prev) => ({ ...prev, color: color }));
    };

    const filterPrice = (val) => {
        setValue({ min: val[0], max: val[1] });
        setFilter((prev) => ({ ...prev, value: value }));
    };

    const filterSortBy = (sort_bys) => {
        setSortBy(sort_bys);
        setFilter((prev) => ({ ...prev, sortBy: sortBy }));
    };

    const filterSearchBy = (search) => {
        setSearchBy(search);
        setFilter((prev) => ({ ...prev, searchBy: search }));
        setFilterContext(true); 
    };

    return (
        <Context.Provider
            value={{
                ...props,
                brand,
                regions,
                status,
                explicit,
                colors,
                sortBy,
                searchBy,
                value,
                filter,
                filterContext,
                setFilterContext: setFilterContext,
                clearFilter: clearFilter,
                filterCategory: filterCategory,
                filterBrand: filterBrand,
                //filterGenre: filterGenre,
                filterStatus: filterStatus,
                filterExplicit: filterExplicit,
                //filterRegions: filterRegions,
                filterColor: filterColor,
                filterPrice: filterPrice,
                filterSearchBy: filterSearchBy,
                filterSortBy: filterSortBy,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default FilterProvider;
