import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P, Image } from "../../AbstractElements";
import logoWhite from "../../assets/images/logo/logo_red.svg";
import logoDark from "../../assets/images/logo/logo_white.svg";
import { AtSign, Smartphone } from "react-feather";
const ResetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [rePasswordValue, setRePasswordValue] = useState("");

  const handleResetPwd = (e) => {
    e.preventDefault();
    if (passwordValue === rePasswordValue) {
      console.log("Password Matched");
      console.log("Password Value's", passwordValue, rePasswordValue);
    } else {
      console.log("Password Not Matched");
    }
  }
  return (
    <Fragment>
      <section>
        <Container className="p-0 login-page" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-card">
                <div>
                  <div>
                    <Link
                      className={`logo ${logoClassMain ? logoClassMain : ""}`}
                      to={process.env.PUBLIC_URL}
                    >
                      <Image
                        attrImage={{
                          className: "img-fluid for-light",
                          src: logoWhite,
                          alt: "looginpage",
                        }}
                      />
                      <Image
                        attrImage={{
                          className: "img-fluid for-dark",
                          src: logoDark,
                          alt: "looginpage",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="login-main">
                    <Form
                      className="theme-form login-form"
                      onSubmit={handleResetPwd}
                    >
                      <H4 attrH4={{ className: "mb-3" }}>
                        Reset Your Password
                      </H4>

                      {/*Reset Pass Form */}
                      <H6 attrH6={{ className: "mt-4" }}>
                        Create Your Password
                      </H6>
                      <FormGroup className="position-relative">
                        <Label className="col-form-label m-0">
                          New Password
                        </Label>
                        <div className="position-relative">
                          <Input
                            className="form-control"
                            type={togglePassword ? "text" : "password"}
                            name="login[password]"
                            required
                            placeholder="*********"
                          />
                          <div
                            className="show-hide"
                            onClick={() => setTogglePassword(!togglePassword)}
                          >
                            <span
                              className={togglePassword ? "" : "show"}
                            ></span>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label m-0">
                          Retype Password
                        </Label>
                        <Input
                          className="form-control"
                          type="password"
                          name="login[password]"
                          required=""
                          placeholder="*********"
                        />
                      </FormGroup>
                      <FormGroup>
                        <div className="checkbox">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">
                            Remember password
                          </Label>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "btn d-block w-100",
                            type: "submit",
                          }}
                        >
                          Done
                        </Btn>
                      </FormGroup>
                      <P attrPara={{ className: "text-start" }}>
                        Already have an password?
                        <a className="ms-2" href="#javascript">
                          Sign in
                        </a>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ResetPwd;
