import { create } from 'zustand';
import UserService from '../../Services/users/UserService'; 
import { toast } from "react-toastify";
const useBankDetailsStore = create((set) => ({
  bankDetails: {},
  bankDetailsLoading: false,
  setBankDetails: (bankDetails) => set({ bankDetails }),
  getUserBankDetails: async () => {
    set({ bankDetailsLoading: true });
    const response = await UserService.getBankDetails();
    if (response.success) {
      set({ bankDetails: response.data, bankDetailsLoading: false });
      return response.data;  
    } else {
      console.error('Failed to fetch bank details', response.error);
      set({ bankDetailsLoading: false });
      return null;  
    }
  },
  addBankDetails: async (data) => {
    set({ bankDetailsLoading: true });
    try {
      const response = await UserService.updateBankDetails({
        bank_name: data.bank_name,
        bank_account_number: data.bank_account_number,
        bank_iban_number: data.bank_iban_number,
        bank_swift_number: data.bank_swift_number,
        bank_city: data.bank_city,
        bank_country: data.bank_country,
      });
  
      if (response.status) {
        toast.success("Bank details updated successfully");
        set({ bankDetails: response.user, bankDetailsLoading: false });
      } else {
        throw new Error("Failed to update bank details");
      }
    } catch (error) {
      console.error("An error occurred while updating bank details", error);
      toast.error("An error occurred while updating bank details");
      set({ bankDetailsLoading: false });
    }
  },
}));

export default useBankDetailsStore;