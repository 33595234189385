import React, { useState, useEffect } from "react";
import {
  Accordion,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ProductService from "../../../../Services/products/ProductService";
import { Btn, Breadcrumbs, H5, H6 } from "../../../../AbstractElements";
import { toast } from "react-toastify";
import ContributorForm from "./ContributorForm";
import NewContributorModal from "./NewContributorModal"
import SpinnerLoader from "../../../Loaders/Spinner";
function ContributorsContainer() {
  const { assetId } = useParams();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  // const { control, handleSubmit, setValue, reset } = useForm({});

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["asset", assetId],
    queryFn: () => ProductService.getAssetById(assetId),
  });

  const {
    isPending: isRoyaltiesPending,
    isError: isRoyaltiesError,
    data: royaltiesData,
    error: royaltiesError,
  } = useQuery({
    queryKey: ["royalties"],
    queryFn: ProductService.getRoyalties,
  });

  const createContributorMutation = useMutation({
    mutationFn: (contributorData) =>
      ProductService.createContributor(assetId, contributorData),
    onSuccess: () => {
      queryClient.invalidateQueries(["asset", assetId]);
      toast.success("Contributor Created Successfully");
    },
    onError: (error) => {
      toast.error(`Creation failed: ${error.error.message}`);
    },
  });

  const updateContributorMutation = useMutation({
    mutationFn: ProductService.updateContributor,
    onSuccess: () => {
      queryClient.invalidateQueries(["asset", assetId]);
      toast.success("Contributor Updated Successfully");
    },
    onError: (error) => {
      toast.error(`Update failed: ${error.error.message}`);
    },
  });

  const deleteContributorMutation = useMutation({
    mutationFn: ProductService.deleteContributor,
    onSuccess: () => {
      queryClient.invalidateQueries(["asset", assetId]);
      toast.success("Contributor Deleted Successfully");
    },
    onError: (error) => {
      toast.error(`Deletion failed: ${error.error.message}`);
    },
  });

  const handleNavBack = () => {
    window.history.back();
  };

  // if (isPending || isRoyaltiesPending) {
  //   return <div>Loading...</div>;
  // }

  if (isError || isRoyaltiesError) {
    const errorMessage = error?.message || royaltiesError?.message;
    return <div>Error: {errorMessage}</div>;
  }

  const handleAddContributor = () => {
    setIsAddModalOpen(true);
  };

  const handleSaveContributor = (contributorData) => {
    return new Promise((resolve, reject) => {
      createContributorMutation.mutate(contributorData, {
        onSuccess: () => {
          setIsAddModalOpen(false);
          resolve();
        },
        onError: reject,
      });
    });
  };
  return (
    <>
    <Breadcrumbs
      parent="Products"
      title="Assets"
      mainTitle="Contributors"
      // TODO: Add Spacing between buttons
      buttons={[
        <Btn
          attrBtn={{
            size: "sm",
            color: "primary",
            className: "mr-2",
            // outline: true,
            onClick: handleNavBack,
          }}
        >
        <i class="fa fa-arrow-left"></i> Go Back
        </Btn>,
        <Btn
          attrBtn={{
            size: "sm",
            color: "primary",
            // outline: true,
            onClick: handleAddContributor,
          }}
        >
        <i className="fa fa-plus me-1"></i>  Add Contributor
        </Btn>,
      ]}
    />
  
    {isPending || isRoyaltiesPending ? (
      <SpinnerLoader />
    ) : (
      <>
        <Col>
          <Accordion
            // defaultActiveKey="0"
            toggle={toggle}
            open={isOpen ? isOpen.toString() : ""}
          >
            <Card>
              <CardHeader>
                <H5>Manage Contributors </H5>
                <span>
                  {"Current Contributors Of "}{" "}
                  <span style={{ color: "#D51515", fontWeight: "bold" }}>
                    {data.data?.asset_name}
                  </span>{" "}
                  {"asset, released on"} {data.data?.release_date}{" "}
                  {"by The artist"}{" "}
                  <span style={{ color: "#D51515", fontWeight: "bold" }}>
                    {data.data?.artist_name}
                  </span>
                </span>
              </CardHeader>
              <CardBody>
                <div className="default-according" id="accordion">
                  {data.data.contributors.map((contributor, i) => (
                    <ContributorForm
                      royalties={royaltiesData.data}
                      key={i}
                      contributor={contributor}
                      isOpen={isOpen}
                      toggle={toggle}
                      updateContributorMutation={updateContributorMutation}
                      deleteContributorMutation={deleteContributorMutation}
                    />
                  ))}
                </div>
              </CardBody>
            </Card>
          </Accordion>
        </Col>
        <NewContributorModal
          isOpen={isAddModalOpen}
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          onSave={handleSaveContributor}
          royalties={royaltiesData.data}
        />
      </>
    )}
  </>
  );
}

export default ContributorsContainer;
