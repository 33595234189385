// PrivateRoute.jsx
import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../_helper/Auth/AuthProvider";
import Error403 from "../Components/Pages/ErrorPages/ErrorPage403";
import Loader from "../Layout/Loader";

const PrivateRoute = ({ roles }) => {
  const { authenticated, userData, loading } = useAuth();
  useEffect(() => {
    localStorage.setItem("authenticated", JSON.stringify(authenticated));
  }, [authenticated]);

  if (loading) {
    return <Loader />;
  }

  if (!authenticated) {
    return <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
  }

  if (roles && roles.indexOf(userData?.role) === -1) {
    return <Error403 />;
  }

  return <Outlet />;
};

export default PrivateRoute;