import React, { useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import DataTable from 'react-data-table-component';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ExchangeService from "../../../Services/exchanges/ExchangeService";
import SpinnerLoader from "../../Loaders/Spinner";
import Swal from "sweetalert2";


const ExchangeRateManual = () => {
    const [modal, setModal] = useState(false);
    const [selectedRate, setSelectedRate] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [errors, setErrors] = useState({});
    const queryClient = useQueryClient();

    const { isLoading, isError, data, error } = useQuery({
        queryKey: ["exchangeRates"],
        queryFn: ExchangeService.getAllExchangeRates,
    });

    const addMutation = useMutation({
        mutationFn: ExchangeService.createExchangeRate,
        onSuccess: () => {
            queryClient.invalidateQueries(["exchangeRates"]);
            toast.success("Exchange rate added successfully");
            setModal(false);
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || `Failed to add exchange rate: ${error.message}`;
            toast.error(errorMessage);
        },
    });

    const updateMutation = useMutation({
        mutationFn: ExchangeService.updateExchangeRate,
        onSuccess: () => {
            queryClient.invalidateQueries(["exchangeRates"]);
            toast.success("Exchange rate updated successfully");
            setModal(false);
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || `Failed to update exchange rate: ${error.message}`;
            toast.error(errorMessage);
        },
    });



    const deleteMutation = useMutation({
        mutationFn: ExchangeService.deleteExchangeRate,
        onSuccess: () => {
            queryClient.invalidateQueries(["exchangeRates"]);
            toast.success("Exchange rate deleted successfully");
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || `Failed to delete exchange rate: ${error.message}`;
            toast.error(errorMessage);
        },
    });

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleAddRate = () => {
        setSelectedRate(null);
        setIsAdding(true);
        setErrors({});
        setModal(true);
    };

    const handleUpdateRate = (rate) => {
        setSelectedRate(rate);
        setIsAdding(false);
        setErrors({});
        setModal(true);
    };

    const handleDeleteRate = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMutation.mutate(id);
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = {
            start_date: event.target.start_date.value,
            end_date: event.target.end_date.value,
            EUR: event.target.EUR.value,
            TND: event.target.TND.value,
            USD: event.target.USD.value,
            GBP: event.target.GBP.value,
        };
        if (isAdding) {
            addMutation.mutate(formData);
        } else {
            updateMutation.mutate({ id: selectedRate.id, formData });
        }
    };

    const tableColumns = [
        { name: "ID", selector: row => row.id, sortable: true },
        { name: "Start Date", selector: row => row.start_date, sortable: true },
        { name: "End Date", selector: row => row.end_date, sortable: true },
        { name: "EUR", selector: row => row.rates.EUR, sortable: true },
        { name: "TND", selector: row => row.rates.TND, sortable: true },
        { name: "USD", selector: row => row.rates.USD, sortable: true },
        { name: "GBP", selector: row => row.rates.GBP, sortable: true },
        {
            name: "Actions",
            cell: row => (
                <div className="todo">
                    <div className="todo-list-wrapper">
                        <div className="todo-list-container todo-list-footer">
                            <ul className="simple-list">
                                <li className="task border-0">
                                    <div className="task-container">
                                        <span className="task-action-btn">
                                            <span
                                                className="action-box large icon-edit"
                                                title="Update"
                                                onClick={() => handleUpdateRate(row)}
                                            >
                                                <i className="icon">
                                                    <i className="fa fa-edit"></i>
                                                </i>
                                            </span>
                                            <span
                                                className="action-box large icon-delete"
                                                title="Delete"
                                                onClick={() => handleDeleteRate(row.id)}
                                            >
                                                <i className="icon">
                                                    <i className="fa fa-trash"></i>
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ),
            center: true,
        },
    ];

    if (isLoading) return <SpinnerLoader />;
    if (isError) return <div>Error loading exchange rates: {error.message}</div>;

    return (
        <>
            <Breadcrumbs
                parent="Exchange"
                title="Exchanges"
                mainTitle="Exchange List"
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleAddRate,
                        }}
                    >
                        <i className="fa fa-plus me-1"></i>Add Exchange Rate
                    </Btn>,
                ]}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h5>Exchange Rates</h5>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={tableColumns}
                                    data={data?.data || []}
                                    striped={true}
                                    center={true}
                                    responsive={true}
                                    pagination
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>{isAdding ? "Add" : "Update"} Exchange Rate</ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="start_date">Start Date</Label>
                                        <Input type="date" name="start_date" id="start_date" defaultValue={selectedRate?.start_date} required />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="end_date">End Date</Label>
                                        <Input type="date" name="end_date" id="end_date" defaultValue={selectedRate?.end_date} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="EUR">EUR</Label>
                                        <Input type="number" step="0.01" name="EUR" id="EUR" defaultValue={selectedRate?.rates?.EUR} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="TND">TND</Label>
                                        <Input type="number" step="0.01" name="TND" id="TND" defaultValue={selectedRate?.rates?.TND} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="USD">USD</Label>
                                        <Input type="number" step="0.01" name="USD" id="USD" defaultValue={selectedRate?.rates?.USD} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="GBP">GBP</Label>
                                        <Input type="number" step="0.01" name="GBP" id="GBP" defaultValue={selectedRate?.rates?.GBP} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">{isAdding ? "Add" : "Update"}</Button>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
        </>
    );
};

export default ExchangeRateManual;