// src/Components/Application/Users/index.jsx
import React, { useState } from "react";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import DataTable from 'react-data-table-component';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UserService from "../../../Services/users/UserService";
import SpinnerLoader from "../../Loaders/Spinner";
import { toast } from "react-toastify";
import { getProfileImageUrl } from "../../../_helper/Images/ImageHelper";
import UserEditModal from "./UserEditModal";
import Swal from "sweetalert2";
import NewUserModal from "./NewUserModal";
import ProductService from "../../../Services/products/ProductService";
import { RefreshCcw } from "react-feather";


function UsersContainer() {
  const queryClient = useQueryClient();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["users"],
    queryFn: UserService.getAllUsers,
  });

  const createUserMutation = useMutation({
    mutationFn: UserService.createUser,
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("User Created Successfully");
      setIsAddModalOpen(false);
    },
    onError: (error) => {
      toast.error(`Creation failed: ${error.error.message}`);
    },
  });
  
  const updateUserMutation = useMutation({
    mutationFn: UserService.updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("User Updated Successfully");
      setIsEditModalOpen(false);
    },
    onError: (error) => {
      toast.error(`Update failed: ${error.error.message}`);
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: UserService.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("User Deleted Successfully");
    },
    onError: (error) => {
      toast.error(`Deletion failed: ${error.error.message}`);
    },
  });

  const handleUpdateUser = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  
  const handleEditUser = (formData) => {
    return new Promise((resolve, reject) => {
      updateUserMutation.mutate({ id: selectedUser.id, formData }, {
        onSuccess: resolve,
        onError: reject
      });
    });
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserMutation.mutate(userId, {
          onSuccess: (response) => {
            if (response.success) {
              Swal.fire("Deleted!", response.data.message, "success");
            } else {
              Swal.fire("Error!", "An error occurred while deleting the user.", "error");
            }
          },
        });
      }
    });
  };

  const handleNewUserModal = () => {
    setIsAddModalOpen(true);
  };

  const handleAddUser = (formData) => {
    return new Promise((resolve, reject) => {
      createUserMutation.mutate(formData, {
        onSuccess: resolve,
        onError: reject
      });
    });
  };

  const syncProductsMutation = useMutation({
    mutationFn: ProductService.syncProducts,
    onSuccess: () => {
      toast.success("Products synced successfully!");
      queryClient.invalidateQueries("products");
    },
    onError: (error) => {
      toast.error(`Failed to sync products: ${error.message}`);
    },
  });

  const handleSyncProducts = () => {
    syncProductsMutation.mutate();
  };

  if (isError) return <p>An error has occurred: {error.message}</p>;

  const tableColumns = [
    {
      name: "Full Name",
      selector: (row) => row["fullname"],
      sortable: true,
      center: false,
    },
    {
      name: "Username",
      selector: (row) => row["username"],
      sortable: true,
      center: true,
    },
    {
      name: "Profile Image",
      selector: (row) => row["image"],
      cell: (row) => (
        <img
          src={row["image"] ? getProfileImageUrl(row["image"]) : require("../../../assets/images/user/7.jpg")}
          alt="Profile"
          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }}
        />
      ),
      center: true,
    },
    {
      name: "Email",
      selector: (row) => row["email"],
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: (row) => row["role"],
      sortable: true,
      center: true,
    },
    {
      name: "Created At",
      selector: (row) => row["created_at"],
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container todo-list-footer">
                <ul className="simple-list">
                  <li className="task border-0">
                    <div className="task-container">
                    <span className="task-action-btn">
                      <span
                          className="action-box large icon-edit"
                          title="Update"
                          onClick={() => handleUpdateUser(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                          className="action-box large delete-btn"
                          title="Delete"
                          onClick={() => handleDeleteUser(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      ),
      center: true,
    },
  ];

  return (
      <>
        <Breadcrumbs
            parent="Users"
            title="Users"
            mainTitle="Users List"
            buttons={[
              <Btn
                  attrBtn={{
                    size: "sm",
                    color: "primary",
                    // outline: true,
                    // onClick: () => queryClient.invalidateQueries(["users"]),
                  }}
              >
               <i class="fa fa-refresh"></i> Sync Labels
              </Btn>,
              <Btn
              attrBtn={{
                size: "sm",
                color: "primary",
                onClick: handleSyncProducts,
              }}
            >
              <i class="fa fa-refresh"></i>  Sync Red Data
              </Btn>,
              <Btn
                  attrBtn={{
                    size: "sm",
                    color: "primary",
                    onClick: handleNewUserModal,
                  }}
              >
                <i className="fa fa-plus me-1"></i>Add User
              </Btn>,         
            ]}
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {isPending ? (
                      <SpinnerLoader />
                  ) : (
                      <DataTable
                          columns={tableColumns}
                          data={data.data}
                          striped={true}
                          center={true}
                          pagination
                      />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {selectedUser && (
            <UserEditModal
                isOpen={isEditModalOpen}
                toggle={() => setIsEditModalOpen(!isEditModalOpen)}
                user={selectedUser}
                onSave={handleEditUser}
            />
        )}

        <NewUserModal
            isOpen={isAddModalOpen}
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            onSave={handleAddUser}
        />
      </>
  );
}

export default UsersContainer;