import { create } from 'zustand';
import AuthService from '../../Services/auth/AuthService'; 

const useSecuritySettingsStore = create((set) => ({
  securitySettings: null,
  setSecuritySettings: (securitySettings) => set({ securitySettings }),
  fetchSecuritySettings: async () => {
    const data = await AuthService.getSecuritySettings();
    // console.log("response from store", data)
    if (data && data.securitySettings) {
      set({ securitySettings: data.securitySettings });
      return data.securitySettings;  
    } else {
      console.error('Failed to fetch security settings');
      return null;  
    }
  },
}));

export default useSecuritySettingsStore;