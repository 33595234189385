import React from 'react';
import Routers from './Route';
import Providers from './Providers';
import ErrorBoundary from './Components/ErrorBoundary';

const App = () => (
    <div className='App'>
        <ErrorBoundary>
            <Providers>
                <Routers />
            </Providers>
        </ErrorBoundary>
    </div>
);

export default App;