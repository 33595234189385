import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { H5, Btn } from '../../../AbstractElements';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axios';
import { useNavigate } from 'react-router-dom';

function IncomeStepTwo({ handleBack, handleNext, incomeId }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

    const handleFileChange = ({ meta, file }, status) => {
        if (status === 'done') {
            setFile(file);
        }
    };

    const uploadChunk = async (chunk, chunkNumber, totalChunks, fileName, fileHash) => {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('fileName', fileName);
        formData.append('fileHash', fileHash);
        formData.append('chunkNumber', chunkNumber);
        formData.append('totalChunks', totalChunks);

        try {
            await axiosInstance.post(`/incomes/upload-chunk/${incomeId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            console.error('Upload error:', error);
            throw error;
        }
    };

    const handleUpload = async () => {
        if (!file) {
            toast.error('No file selected!');
            return;
        }

        setIsUploading(true);

        const fileName = file.name;
        const fileHash = `${fileName}-${file.size}-${file.lastModified}`;
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
            const start = chunkNumber * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);

            try {
                await uploadChunk(chunk, chunkNumber + 1, totalChunks, fileName, fileHash);
            } catch (error) {
                toast.error(`Failed to upload chunk ${chunkNumber + 1}: ${error.message}`);
                setIsUploading(false);
                return;
            }
        }

        toast.success('File uploaded successfully!');
        queryClient.invalidateQueries(['incomes']);
        setIsUploading(false);
        navigate(`${process.env.PUBLIC_URL}/app/income/income-list`);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'You have unsaved changes, do you really want to leave?';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Col sm="12">
            <Card className="card-absolute">
                <CardHeader className="bg-primary">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <H5>Invoice File</H5>
                    </div>
                </CardHeader>
                <CardBody>
                    <Dropzone
                        className="dropzone dz-clickable"
                        onChangeStatus={handleFileChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="Drop A File"
                        styles={{
                            dropzone: { width: '100%', height: 150 },
                            dropzoneActive: { borderColor: 'green' },
                        }}
                    />
                </CardBody>
                <CardFooter className="text-end">
                    <Btn
                        attrBtn={{
                            color: 'primary',
                            className: 'm-r-15',
                            onClick: handleUpload,
                            disabled: !file || isUploading,
                        }}
                    >
                        {isUploading ? 'Uploading...' : 'Upload'}
                    </Btn>
                </CardFooter>
            </Card>
        </Col>
    );
}

export default IncomeStepTwo;