// src/Components/Application/Users/UserEditModal.jsx
import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardBody,
  Modal,
  CardHeader,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Btn, Image, H6, UL, LI, H5, P } from "../../../AbstractElements";
import SocialData from "../../Common/Component/SocialData";
import SocialLinks from "../../Common/Component/SocialLinks";
import { CardFooter } from "react-bootstrap";
import { getProfileImageUrl } from "../../../_helper/Images/ImageHelper";


import TailChase from "../../Loaders/TailChase";

const UserEditModal = ({ isOpen, toggle, user, onSave }) => {
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleImageClick = (event) => {
    event.stopPropagation();
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(img);
      setNewImageSelected(true);
      setPreviewImage(URL.createObjectURL(img));
    } else {
      setSelectedImage(null);
      setNewImageSelected(false);
      setPreviewImage(null);
    }
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: user,
  });

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  const [newImageSelected, setNewImageSelected] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    // console.log("data", data);
    // console.log("newImageSelected", newImageSelected);
    Object.keys(data).forEach((key) => {
      if (key === "image") {
        if (newImageSelected) {
          formData.append(key, selectedImage);
        }
      } else {
        formData.append(key, data[key]);
      }
    });
    onSave(formData).then(() => {
      setSelectedImage(null); 
      setPreviewImage(null);
      setNewImageSelected(false); 
      setIsLoading(false);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Edit User</ModalHeader>
      <ModalBody>
        {/* <CardHeader className='cardheader'>
        </CardHeader> */}
        <Card>
          <CardBody>
            <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
              {/* <Row className='mb-5 '>
              <div className='profile-title'>
                <div className='media'>
                  <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../assets/images/user/7.jpg')}` }} />
                  <div className='media-body'>
                    <P>{user.role}</P>
                  </div>
                </div>
              </div>
            </Row> */}
              <Row>
                <FormGroup className="mb-3">
                  <Row md={5} className="justify-content-center">
                    <div className="profile-title" onClick={handleImageClick}>
                      <div className="media">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />
                        
                        <Image
                          attrImage={{
                            className: "img-100 m-0 rounded-circle",
                            alt: "profile-image",
                            src: previewImage
                              ? previewImage
                              : user.image
                              ? getProfileImageUrl(user.image)
                              : require("../../../assets/images/user/7.jpg"),
                          }}
                        />
                        
                        <div className="edit-icon">
                          <div
                            className="icon-wrapper"
                            onClick={handleImageClick}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="icofont icofont-pencil-alt-5"></i>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </Row>
                  {/* <Row md={6} className="justify-content-center">
                        <P>{user.username}</P>
                 </Row> */}
                </FormGroup>
                <hr className="mt-4 mb-4" />

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "form-label" }}>FullName</H6>
                    <Controller
                      name="fullname"
                      className="form-control"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "form-label" }}>Username</H6>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "form-label" }}>Email</H6>
                    <Controller
                      name="email"
                      className="form-control"
                      control={control}
                      render={({ field }) => <Input type="email" {...field} />}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <H6 attrH6={{ className: "form-label" }}>Role </H6>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                        <Input type="select" {...field}>
                          <option value="admin">Admin</option>
                          <option value="staff">Staff</option>
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              </Row>
              <CardFooter className="text-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "m-r-15",
                    type: "submit",
                  }}
                >
                  {isLoading ? <TailChase /> : "Save"}
                </Btn>
              </CardFooter>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default UserEditModal;
