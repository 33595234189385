const IMG_STORAGE = process.env.REACT_APP_IMG_STORAGE;
const AMAZON_STORAGE = process.env.REACT_APP_AMAZON_BUCKET;

export function getProfileImageUrl(imagePath) {
  return `${IMG_STORAGE}/${imagePath}`;
}

export function getLabelImageUrl(imagePath) {
  return `${IMG_STORAGE}/labels_pictures/${imagePath}`;
}

export function getProductCoverUrl(imagePath) {
  return `${AMAZON_STORAGE}/product/coverProducts/${imagePath}`;
}

export function getAudioFile(audioFilePath) {
  return `${IMG_STORAGE}/product/asset/${audioFilePath}`;
}

export function getDspImageUrl(imagePath) {
  return `${IMG_STORAGE}/dsp_pictures/${imagePath}`;
}