import axios from 'axios';
import { decryptToken } from '../utils/tokenUtils';
import AuthService from '../Services/auth/AuthService';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Decrypt the token before sending
      const decryptedToken = decryptToken(token.replace(/^encrypted:/, ''));
      config.headers.Authorization = `Bearer ${decryptedToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      const isLoggedIn = AuthService.isAuthenticated();
      if (!isLoggedIn) {
        localStorage.removeItem('authToken');
      } else {
        AuthService.logout();
      }
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
