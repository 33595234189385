import React, {useState} from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import { Stepper, Step } from "react-form-stepper";
import InvoiceStepOne from './InvoiceStepOne';
import InvoiceStepTwo from './InvoiceStepTwo';
import { Container } from 'reactstrap';
// Stepper 
// Form with react Hook Forms 
// First Step Form : Data + normal File Upload
// Second Step Form: Dropzone File Upload one single file
// On Submit Form -> Redirect to Invoice List Page

function NewInvoice() {

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <>
     <Breadcrumbs
      parent="Invoices"
      title="Invoices"
      mainTitle="Create Invoice"
      buttons={[
        // <Btn
        //   attrBtn={{
        //     size: "sm",
        //     color: "primary",
        //     // onClick: handleSyncInvoices, // Replace with your function
        //   }}
        // >
        //   <i className="fa fa-refresh"></i> Sync Invoices
        // </Btn>,
      ]}
    />
     <Container fluid={true}>
      <Stepper activeStep={activeStep}>
        <Step label="Invoice Step One" />
        <Step label="Invoice Step Two" />
      </Stepper>

      {activeStep === 0 && (
        <InvoiceStepOne
          handleNext={handleNext}
          
        />
      )}
      {activeStep === 1 && (
        <InvoiceStepTwo
          handleBack={handleBack}
          handleNext={handleNext}
         
        />
      )}
    </Container>

    </>
  )
}

export default NewInvoice